import $ from 'jquery';


var ready = function() {
  $(".js-photoreport").mousemove(function(e) {
    var count = $(this).find("figure").length;
    var parentOffset = $(this).offset();
    var relX = e.pageX - parentOffset.left;
    var shift = Math.floor((relX / 210) * count);

    shift = shift > 0 ? shift : 0;

    $(this)
      .find(".js-slider")
      .css("margin-left", shift * -210 + "px");
  });

  $(".js-photoreport").mouseleave(function(e) {
    $(this)
      .find(".js-slider")
      .css("margin-left", "0");
  });
};

$(document).ready(ready);
