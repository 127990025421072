import { v4 as uuidv4 } from 'uuid';
import Inputmask from 'inputmask';
import animateValue from '../animate-value';
import './appearance';
import {
  applyPromocode,
  calculateCartTotal,
  isOfProductVariant,
} from '../../shared/billing';

const productDetails = {
  name: 'Футболка «Сложный дизайнер»',
  color: {
    black: { name: 'чёрная', fill: 'rgb(0,0,0)' },
    pink: { name: 'сияющая', fill: 'rgb(250,215,225)' },
    white: { name: 'белая', fill: 'rgb(250,245,240)' },
  },
  gender: { male: 'мужской', female: 'женский' },
};

const orderTableObserver = new IntersectionObserver(
  entries => {
    const orderButton = document.querySelector('.scroll-to-order-button');
    entries.forEach(entry =>
      orderButton.classList.toggle('move-up', !entry.isIntersecting)
    );
  },
  {
    rootMargin: `1000px 0px 0px 0px`,
  }
);

const updateNumberOnScrollButton = cart => {
  const num = document.querySelector('.order-number');
  const quantity = Object.values(cart).reduce(
    (acc, item) => acc + item.quantity,
    0
  );
  if (quantity >= 2) {
    num.textContent = ` ${quantity}`;
  } else {
    num.textContent = '';
  }
};

const addProduct = (productObj, id) => {
  const productTemplate = document.querySelector('#product-item');
  const productSummary = document.querySelector('.product.summary');
  const newEl = productTemplate.content.firstChild.cloneNode(true);

  const title = newEl.querySelector('.title');
  const colorFill = newEl.querySelector('svg');
  const colorName = newEl.querySelector('.product-color-name');
  const sizeLetter = newEl.querySelector('.size-letter');
  const genderName = newEl.querySelector('.gender');
  const productQuantity = newEl.querySelector('.counter-buttons > span');
  const productPrice = newEl.querySelector('.product-price');

  productSummary.classList.remove('hidden');
  title.textContent = productObj.title;
  colorFill.setAttribute('fill', productDetails.color[productObj.color].fill);
  colorName.textContent = productDetails.color[productObj.color].name;
  sizeLetter.textContent = productObj.size;
  genderName.textContent = productDetails.gender[productObj.gender];
  productQuantity.textContent = productObj.quantity;

  // productsOfCurrentKind is global var, initialized in shop/index.pug

  const currentProduct = productsOfCurrentKind.find(p => p.id === productObj.productId)
  productPrice.textContent =
    productObj.totalPrice || productObj.price * productObj.quantity;
  newEl.setAttribute('data-id', id);

  newEl.classList.toggle('removed', productObj.quantity === 0);

  productSummary.before(newEl);
};

const renderCart = () => {
  const cart = JSON.parse(window.localStorage.getItem('weird-collection'));

  // Render cart only if it is not empty
  if (!cart || !Object.keys(cart).length) return;

  const orderTable = document.querySelector('.order-table');
  const products = orderTable.querySelectorAll('.product');
  const purchaseWrapper = document.querySelector('.purchase-form');
  const purchaseButton = document.querySelector('.purchase-button');
  const buttonPrice = purchaseButton.querySelector('.cart-price');
  const buttonCurrency = purchaseButton.querySelector('.cart-currency');
  const promocode = JSON.parse(purchaseWrapper.dataset.promocode || 'null');

  for (const p of products) {
    if (!p.classList.contains('summary')) p.remove();
  }

  Object.keys(cart).forEach(key => {
    const product = productsOfCurrentKind.find(({ id }) => id === cart[key].productId);
    cart[key].key = key;
    cart[key].price = product ? product.priceInt : 0;
  });

  const cartWithPromocode = applyPromocode([...Object.values(cart)], promocode, false);
  const totalCartPrice = calculateCartTotal(cartWithPromocode);

  try {
    cartWithPromocode.forEach(item => addProduct(item, item.key));
  } catch (err) {
    window.localStorage.removeItem('weird-collection');
    window.location.reload();
  }

  document.querySelector('.product-price-total').textContent = totalCartPrice;

  purchaseButton.disabled = totalCartPrice === 0;

  if (totalCartPrice !== 0) {
    buttonPrice.textContent = totalCartPrice;
    buttonCurrency.textContent = '₽';
    
    // продажи приостановлены
    if (purchaseWrapper.getAttribute('data-kind') === 'weird-collection') {
      purchaseButton.disabled = true;
    } else {
      purchaseButton.disabled = false;
    }
    
    orderTableObserver.observe(orderTable);
  } else {
    buttonPrice.textContent = '';
    buttonCurrency.textContent = '';
    purchaseButton.disabled = true;
    orderTableObserver.unobserve(orderTable);
  }

  initializeQuantityButtons();
  updateNumberOnScrollButton(cart);
};

const initializeProductChoice = shoppingItem => {
  const addToCartButton = shoppingItem.querySelector('.addToCart');
  const radios = shoppingItem.querySelectorAll('input[type=radio]');
  const optionNames = ['color', 'gender', 'size'];

  const setSizeAndGender = radio => {
    if (!radio.checked) return;

    optionNames.forEach(name => {
      if (radio.name.includes(name)) {
        shoppingItem.dataset[name] = radio.value;
      }
    });
  };

  radios.forEach(radio => {
    setSizeAndGender(radio);
    radio.addEventListener('change', e => {
      setSizeAndGender(e.target);
    });
  });

  addToCartButton.addEventListener('click', e => {
    const orderButton = document.querySelector('.scroll-to-order-button');
    const { product, color, size, gender } = shoppingItem.dataset;
    const title = shoppingItem.dataset.title || productDetails.name
    const detailedTitle = shoppingItem.dataset.detailedTitle || title
    const id = uuidv4();

    const productData = JSON.parse(product)

    const cartStr =
      window.localStorage.getItem('weird-collection') || '{}';
    if (!cartStr) {
      window.localStorage.setItem('weird-collection', '{}');
    }

    const cart = JSON.parse(cartStr);
    const productVariant = {
      color,
      title,
      detailedTitle,
      size,
      gender,
      productId: productData.id,
    };

    const [existingProductId] =
      Object.entries(cart).find(([, item]) =>
        isOfProductVariant(item, productVariant)
      ) || [];

    if (existingProductId) {
      cart[existingProductId].quantity += 1;
    } else {
      cart[id] = { ...productVariant, quantity: 1 };
    }

    window.localStorage.setItem('weird-collection', JSON.stringify(cart));

    orderButton.classList.add('cart-update');
    setTimeout(() => orderButton.classList.remove('cart-update'), 600);

    renderCart();
  });
};

const initializeProductOptions = shoppingItem => {
  const textEditInputs = shoppingItem.querySelectorAll('.custom-word input');
  const sizeSelector = shoppingItem.querySelectorAll('.select-size .ids__radiogroup-option');
  const genderSelector = shoppingItem.querySelectorAll('.select-gender input[type=radio]');
  const colorSelector = shoppingItem.querySelectorAll('.select-color input[type=radio]');

  ['click', 'mouseover', 'mouseout'].forEach(evt =>
    sizeSelector.forEach(input => {
      input.addEventListener(evt, e => {
        const el = e.target.closest('.shop-item');
        const genderInputChecked = el.querySelector(
          '.select-gender input:checked'
        );
        const itemSizeValues = [...el.querySelectorAll('.size-info .value')];

        let sizeInputChecked;

        if (evt === 'click' || evt === 'mouseout') {
          sizeInputChecked = el.querySelector('.select-size input:checked');
        } else {
          sizeInputChecked = e.target
            .closest('.ids__radiogroup-option')
            .querySelector('.select-size input');
        }

        fillSizes(
          genderInputChecked,
          sizeInputChecked,
          itemSizeValues
        );
      });
    })
  );

  textEditInputs.forEach(input => {
    const inputFilter = /[^а-яёa-z\-()!?#]/g;
    const { titleBeginning, detailedTitleBeginning } = shoppingItem.dataset
    const ending = shoppingItem.querySelector('.tshort-text span.ending');
    const secondWord = shoppingItem.querySelector('.tshort-text span.second-word');

    input.addEventListener('input', e => {
      let inputValue;
      inputValue = e.target.value

      if(!inputValue.match(inputFilter)) {
        e.target.parentNode.dataset.value = inputValue;

        if(e.target.classList.contains('ending')) {
          ending.textContent = inputValue;
        } else if (e.target.classList.contains('second-word')) {
          secondWord.textContent = inputValue;
        }

        const customTitlePart = ending.textContent + ' ' + secondWord.textContent + '»'
        shoppingItem.dataset.title = titleBeginning + customTitlePart
        shoppingItem.dataset.detailedTitle = detailedTitleBeginning + customTitlePart
      }
    })

    input.addEventListener('keypress', e => {
      if (e.target.value.match(inputFilter)) {
        e.target.value = e.target.value.replace(inputFilter,'');
      }
    })
    input.addEventListener('keyup', e => {
      if (e.target.value.match(inputFilter)) {
        e.target.value = e.target.value.replace(inputFilter,'');
        errorShaking(e.target);
      }
    })
    input.addEventListener('paste', e => {
      if (e.target.value.match(inputFilter)) {
        e.target.value = e.target.value.replace(inputFilter,'');
        errorShaking(e.target);
      }
    })
  })

  genderSelector.forEach(input => {
    input.addEventListener('click', e => {
      initializeFillSizes(e.target.closest('.shop-item'));
    });
  });

  const updateColor = (value) => {
    colorSelector.forEach(input => {
      shoppingItem.classList.remove(input.value);
    });
    shoppingItem.classList.add(value);
  };

  colorSelector.forEach(input => {
    if (input.checked) {
      updateColor(input.value);
    }

    input.addEventListener('click', () => {
      updateColor(input.value);
    });
  });
};

const initializeQuantityButtons = () => {
  const quantityButtons = document.querySelectorAll(
    '.counter-buttons > button'
  );

  quantityButtons.forEach(button => {
    button.addEventListener('click', e => {
      const product = e.target.closest('.product');
      const { id } = product.dataset;
      const cart = JSON.parse(window.localStorage.getItem('weird-collection') || '{}');

      if (e.target.textContent === '−' && cart[id].quantity > 0) {
        cart[id].quantity -= 1;
      }

      if (e.target.textContent === '+') {
        cart[id].quantity += 1;
      }

      window.localStorage.setItem('weird-collection', JSON.stringify(cart));
      renderCart();
    });
  });
};

const checkCartLocalStorage = (cart) => {
  const requiredProps = ['title']

  return Object.entries(cart).reduce((newCart, [id, product]) => {
    if (product.quantity === 0) return newCart;

    const { items, ...rest } = product;

    requiredProps.forEach(prop => {
      if (!rest[prop]) {
        throw new Error(`Missing required prop "${prop}"`)
      }
    })
    rest.detailedTitle = rest.detailedTitle || rest.title;
    newCart[id] = rest;

    return newCart;
  }, {});
};

const initializeCartOnLoad = () => {
  const purchaseButton = document.querySelector('.purchase-button');
  const orderTable = document.querySelector('.order-table');

  // remove promocode, all discounted prices
  // and all items with quantity === 0 on page reload
  const cartStr = localStorage.getItem('weird-collection');

  if (!cartStr) {
    purchaseButton.disabled = true;
    return;
  }

  const cart = JSON.parse(cartStr);

  try {
    const newCart = checkCartLocalStorage(cart);
    const isEmpty = Object.values(newCart).length === 0;

    purchaseButton.disabled = isEmpty;

    if (!isEmpty) {
      orderTableObserver.observe(orderTable);
    }

    localStorage.setItem('weird-collection', JSON.stringify(newCart));
  } catch (err) {
    localStorage.removeItem('weird-collection');
    throw new Error(err);
  }
};

// Переключалки длины и ширины размеров «Длина 5 см, ширина по груди 5 см»
const fillSizes = (
  genderInputChecked,
  sizeInputChecked,
  itemSizeValues
) => {
  let itemSizes;

  if (genderInputChecked.value === 'female') {
    itemSizes = JSON.parse(sizeInputChecked.getAttribute('data-size-female'));
  } else {
    itemSizes = JSON.parse(sizeInputChecked.getAttribute('data-size-male'));
  }

  itemSizes.forEach((item, i) => {
    itemSizeValues[i].textContent = item;
  });
};

const initializeFillSizes = el => {
  const genderInputChecked = el.querySelector('.select-gender input:checked');
  const sizeInputChecked = el.querySelector('.select-size input:checked');
  const itemSizeValues = [...el.querySelectorAll('.size-info .value')];

  fillSizes(genderInputChecked, sizeInputChecked, itemSizeValues);
};

const initializeTelMask = () => {
  const phoneInput = document.querySelector('input[name="phone"]');
  Inputmask('+<7 999 999-99-99>|<375 99 999-99-99>', {
    groupmarker: ['<', '>'],
    showMaskOnHover: false,
  }).mask(phoneInput);
};

const keepFormValuesInSession = form => {
  const formInputs = form.querySelectorAll('input');
  const savedFormData = sessionStorage.getItem('weird-collection');

  if (savedFormData) {
    const formData = JSON.parse(sessionStorage.getItem('weird-collection'));
    const inputs = [...formInputs].filter(
      input => input.name !== 'cities-list'
    );

    inputs.forEach(input => {
      if (input.type === 'radio') {
        input.checked = formData[input.name] === input.value;
      } else {
        input.value = formData[input.name];
      }

      if (
        input.name === 'address' &&
        input.nextSibling &&
        input.nextSibling.classList.contains('chosen-address')
      ) {
        input.nextSibling.textContent = input.value.replace(
          /boxberry\s*\d*\./i,
          ''
        );
      }
    });
  }

  form.addEventListener('change', () => {
    const formData = JSON.stringify(Object.fromEntries(new FormData(form)));
    window.formData = formData;
    sessionStorage.setItem('weird-collection', formData);
  });
};

const errorShaking = elem => {
  elem.classList.add('error');
  setTimeout(() => elem.classList.remove('error'), 700);
}

document.addEventListener('DOMContentLoaded', () => {
  if (!document.querySelector('.content-wrapper.weird-collection')) return;

  const shoppingItems = document.querySelectorAll('.shop-item');
  const orderTable = document.querySelector('.order-table');
  const boxberryLink = document.querySelector('.pseudo-link');
  const confirmationText = document.querySelector('#confirmation-text');

  const purchaseForm = document.querySelector('#purchase-form');
  const purchaseFormInputs = document.querySelectorAll('#purchase-form input');

  if (shoppingItems.length && orderTable) {
    initializeCartOnLoad();
    renderCart();
    initializeTelMask();

    shoppingItems.forEach(item => {
      if (!item.classList.contains('combo')) {
        initializeProductChoice(item);
        initializeFillSizes(item);
        initializeProductOptions(item);
      }
    });

    // поворот футболки при скролле
    const pictures = document.querySelectorAll('.custom-picture-wrapper');

    window.addEventListener('scroll', () => {
      const { scrollY, innerHeight } = window;

      pictures.forEach(picture => {
        const { parentNode } = picture;
        const pictureHeight = parentNode.clientHeight;
        const { top } = parentNode.getBoundingClientRect();

        const pictureOffsetTop = top + scrollY - innerHeight;
        const pictureOffsetBottom = top + scrollY + pictureHeight;

        const diff = pictureOffsetBottom - pictureOffsetTop;
        const delta = pictureOffsetBottom - scrollY;

        const rotationRange = delta / diff * 100;

        picture.style.transform = `perspective(30em) rotateY(${rotationRange.toFixed(2) - 30}deg)`;
      });
    });
  }

  if (boxberryLink) {
    boxberryLink.addEventListener('click', () => {
      const addressInput = document.querySelector('input[name="address"]');
      const closeButtons = document.querySelectorAll('.boxberry_container_close');
      const chosenAddress = document.querySelector('.chosen-address');
      const citiesSelect = document.querySelector('#cities-list');

      const callbackFunction = result => {
        const addressForForm = result.address;
        const fullAddress = `Boxberry ${result.id}. ${result.address}`;

        chosenAddress.textContent = addressForForm;
        addressInput.value = fullAddress;
        purchaseForm.dispatchEvent(new Event('change'));
      };

      closeButtons.forEach(button => {
        const container = button.closest('.boxberry_container');
        container.style.display = 'none';
      });

      window.boxberry.open(
        callbackFunction,
        '1$f57bda8ba302968088908a68d71bf40a',
        citiesSelect.value || citiesSelect.placeholder,
        '', // target_start code of sending office
        '', // ordersum price
        500, // weight (required)
        0 // paysum (0 if prepaid)
        // 200, // height
        // 200, // width
        // 200 // depth
      );
      return false;
    });
  }

  if (purchaseForm) {
    keepFormValuesInSession(purchaseForm);
  }

  if (confirmationText) {
    localStorage.removeItem('weird-collection');
  }

  purchaseFormInputs.forEach(el => {
    el.addEventListener('blur', event => {
      setTimeout(() => {
        event.target.classList.add('hide-autofill');
      }, 100);
    });
  });
});

export default renderCart;
