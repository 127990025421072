import $, { each } from "jquery";

const ready = function() {
  // handle color schemes
  let currentColorScheme;
  let colorScheme;

  if (localStorage.getItem("color-scheme")) {
    colorScheme = localStorage.getItem("color-scheme");
  } else {
    colorScheme = $(
      ".color-switch input:radio[name=color-switcher]:checked"
    ).val();
  }
  setColorScheme(colorScheme);
  $(`.color-switch input:radio`)
    .filter(`[value=${colorScheme}]`)
    .prop("checked", true);
  currentColorScheme = colorScheme;

  $(".color-switch input:radio").change(function() {
    setColorScheme($(this).val());
  });


  // handle units
  let currentGlobalUnits;
  let currentPxInEm;

  let globalUnits;
  if (localStorage.getItem("global-units")) {
    globalUnits = localStorage.getItem("global-units");
  } else {
    globalUnits = $(
      ".units-selector input:radio[name=units-selector]:checked"
      ).val();
  }

  setGlobalUnits(globalUnits);

  $(`.units-selector input:radio`)
    .filter(`[value=${globalUnits}]`)
    .prop("checked", true);
  currentGlobalUnits = globalUnits; // not sure if this is necessary

  $(".units-selector input:radio").change(function() {
    setGlobalUnits($(this).val());
  });
    
  let pxInEm;
  if (localStorage.getItem("px-in-em")) {
    pxInEm = localStorage.getItem("px-in-em");
  } else {
    pxInEm = $(".em-converter input").val();
  }
  setPxValues(pxInEm);
  $(".em-converter input").val(pxInEm);
  currentPxInEm = pxInEm;

  $(".em-converter input").change(function() {
    setPxValues($(this).val());
  });

  // functions
  function setColorScheme(newColorScheme) {
    $("body").removeClass(currentColorScheme);
    $("body").addClass(newColorScheme);
    localStorage.setItem("color-scheme", newColorScheme);
    $(`.color-switch input:radio`)
      .filter(`[value=${newColorScheme}]`)
      .prop("checked", true);

    $(".palette").addClass("hidden");
    $(`.${newColorScheme}-palette`).removeClass("hidden");


    currentColorScheme = newColorScheme;
  }

  function setGlobalUnits(newGlobalUnits) {
    switch (newGlobalUnits) {
      case "em":
      $(".em-converter").removeClass("visible");

      $(".px").hide();
      $(".em").show();
      break;
      case "px":
      $(".em-converter").addClass("visible");
      $(".px").show();
      $(".em").hide();
    }

    localStorage.setItem("global-units", newGlobalUnits);
    $(`.units-selector input:radio`)
      .filter(`[value=${newGlobalUnits}]`)
      .prop("checked", true);
    currentGlobalUnits = newGlobalUnits;
  }
    
  function setPxValues(newPxInEm) {
    $(".props").each(function() {
      let base = $(this)
      .find(".base")
      .text()
      ? $(this)
        .find(".base")
        .text()
      : "1";
      // console.log(base);

      $(this)
      .find(".value")
      .each(function() {
        let emValue = $(this)
        .find(".em")
        .text();

        if (
        !$(this)
          .find(".em")
          .hasClass("base")
        ) {
        emValue *= base;
        }

        let pxValue = Math.floor(emValue * newPxInEm * 10) / 10;

        $(this)
        .find(".px")
        .text(pxValue);
      });
    });

    localStorage.setItem("px-in-em", newPxInEm);
    $(".em-converter input").val(newPxInEm);
    currentPxInEm = newPxInEm;

    if($(".orb-options-wrapper").length) {
      $(".depends-em").css("font-size", `${newPxInEm}px`);
    }
  }


	//awakeness
	const awakeness = () => {
	  let windowHeight = window.innerHeight;
  
	  $(window).resize(function() {
		windowHeight = window.innerHeight;
	  });
  
	  $(
		".orb .report-infographics .bar, .orb .poll-infographics .bar, .orb mark"
	  ).each(function() {
		var elementMiddle = $(this).offset().top + $(this).height() / 2;
  
		if (
		  elementMiddle < $(window).scrollTop() ||
		  elementMiddle > $(window).scrollTop() + windowHeight
		) {
		  $(this).addClass("sleep");
		} else {
		  $(this).removeClass("sleep");
		}
	  });
	};
	awakeness();
	window.addEventListener("scroll", awakeness);

	// download-menu
	$(".download-plate").hover(
		function(e) {
		  $(this).addClass("hover");
		},
		function(e) {
		  $(this).removeClass("hover");
		  $(this)
			.find(".download-menu")
			.removeClass("visible");
	});
	
	$(".download-plate").on("click", function(e) {
		$(this)
		  .find(".download-menu")
		  .addClass("visible");
	});
};
  
$(document).ready(ready);