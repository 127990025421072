import $ from "jquery";

const ready = function() {
  let isOpen = false;

  $(".dropdown button").on("click", function(e) {
    const isActive = $(e.target).hasClass("active");
    $(".dropdown button").removeClass("active rotated");

    console.log(isActive, isOpen);

    if (!isOpen && !isActive) {
      $(e.target)
        .parent()
        .find(".dropdown-popup")
        .fadeIn(150);
      $(e.target).addClass("active");
      $(e.target).addClass("rotated");
      isOpen = true;

    } else if (isOpen && !isActive) {
      $(".dropdown-popup").fadeOut(150);
      $(e.target)
        .parent()
        .find(".dropdown-popup")
        .fadeIn(150);
      $(e.target).addClass("active");
      $(e.target).addClass("rotated");
      isOpen = true;

    } else if (isOpen && isActive) {
      $(".dropdown-popup").fadeOut(150);
      isOpen = false;
    }

    e.stopPropagation();
  });

  // скрывает по оутсайд-клику
  $(document).on("click", function(e) {
    if (e.target.className !== "dropdown-popup") {
      $(".dropdown-popup").fadeOut(150);
      $(".dropdown button").removeClass("active rotated");
      isOpen = false;
    }
  });
};

$(document).ready(ready);