import $ from 'jquery';



var ready = function () {


  /// D E L O

  $('.delo-tag-link').on('click',function(e){
    e.preventDefault();
    $('.delo-tag-link').removeClass('active');  
    $(this).addClass('active');
    let hash = this.hash.substr(1);
    
    $('.delo-card').hide();
    $(`.${hash}`).show();
    
  })
  
  $('.waat').on('click',function(e){
    e.preventDefault();
    $(this).hide();
    $('.waat-answer').removeClass('hidden');
  })


  if ($(".delo-control-panel-parallax").length) {
    deloCPScroll();
    $(window).scroll(deloCPScroll);
    var tempDelta;
    var pointsDelta = 0;


    function deloCPScroll() {
      let containerTop = $(".delo-control-panel-parallax").offset().top - $(window).height();
      tempDelta = $(window).scrollTop() > containerTop ? $(window).scrollTop() -  containerTop : 0;
    };

    function deloCPParallax() {

      pointsDelta += (tempDelta - pointsDelta) / 2;

      $(".delo-control-panel-parallax img.sample").css(
        "transform",
        "translateY(" + pointsDelta / -6 + "px)"
      );
    

    };

    var cycler = setInterval(deloCPParallax, 5);
  }


  

  /// L O G E K S I M

  var linkID = "#logo0";

  $(".switching-link").click(function(e) {
    e.preventDefault();

    var newLinkID = $(e.target).attr("href");
    if (newLinkID != linkID) {
      $(linkID).fadeToggle(250);
      $(newLinkID).fadeToggle(250);
      $(".switching-link").removeClass("active");
      $(e.target).addClass("active");

      linkID = newLinkID;
    }
  });

  /// A A A

  aaaScrollDoings();
  $(window).scroll(aaaScrollDoings);

  var aaaDelta;

  function aaaScrollDoings() {
    aaaDelta = $(window).scrollTop() > 500 ? $(window).scrollTop() - 500 : 0;

    $(".js-aaa-column-2").css("top", aaaDelta / -1.45 + "px");
    $(".js-aaa-column-3").css("top", aaaDelta / -1.25 + "px");
    $(".js-aaa-column-4").css("top", aaaDelta / -3.3 + "px");
  }

  /// R O C K E T

  rocketScrollDoings();

  $(window).scroll(rocketScrollDoings);

  var rocketDelta;

  function rocketScrollDoings() {
    rocketDelta = $(window).scrollTop() > 0 ? $(window).scrollTop() - 0 : 0;

    $(".js-rocket-column-2").css(
      "transform",
      "translateY(" + rocketDelta / -2.3 + "px)"
    );
  }

  $(".new_record .js-rec-text textarea").on("keyup", function() {
    if ($(this).val().length > 0) {
      $(".new_record .js-rec-pic").hide();
    } else {
      $(".new_record .js-rec-pic").show();
    }
  });

  $(".js-rec-delete").on("click", function() {
    $this = $(this);
    $.ajax({
      url: $(this).attr("href"),
      type: "delete",
      success: function(data) {
        $this.closest(".journal-container").toggleClass("deleted");
      }
    });
    return false;
  });

  /// D O P I N G

  $(".js-true-link").click(function() {
    var hrefURL = $(this).attr("href");
    window.location.href = hrefURL;
  });


  ////
  $('.scroll-link').click(function(e) {
		e.preventDefault();

		var linkOffset = -40;
		if ($($.attr(this, 'href')).data('scroll-link-offset')) {
			linkOffset += $($.attr(this, 'href')).data('scroll-link-offset')
		};

		$('html, body').animate({
			scrollTop: $($.attr(this, 'href')).offset().top + linkOffset

		}, 500);
	});

  /// MAIN
  
  $('.authors-list-switcher').click(function(e) {
    e.preventDefault();
    $('.hidden-authors').removeClass('hidden');
    $(this).addClass('hidden');
  });

  /// I N V E S T O R

  if ($(".ni-stuff").length) {
    investorScroll();
    $(window).scroll(investorScroll);
    var tempDelta;
    var niDelta = 0;


    function investorScroll() {
      tempDelta = $(window).scrollTop() > 150 ? $(window).scrollTop() - 150 : 0;
    };

    function investorParallax() {

      niDelta += (tempDelta - niDelta) / 10;

      $(".ni-stamp img").css(
        "transform",
        "translateY(" + niDelta / -2.5 + "px) rotate(" + niDelta / -60 + "deg)"
      );
      $(".ni-coin img").css(
        "transform",
        "translateY(" + niDelta / -1.2 + "px) rotate(" + niDelta / 20 + "deg)"
      );
      $(".ni-envelope2 img").css(
        "transform",
        "translateY(" + niDelta / -8 + "px) translateX(" + niDelta / -30 + "px)"
      );
      $(".ni-card1 img").css("transform", "translateY(" + niDelta / -5 + "px)");
      $(".ni-card2 img").css(
        "transform",
        "translateY(" + niDelta / -4.5 + "px) translateX(" + niDelta / -30 + "px)"
      );
      $(".ni-envelope1 img").css(
        "transform",
        "translateY(" + niDelta / -4 + "px) translateX(" + niDelta / -40 + "px)"
      );
    };


    var cycler = setInterval(investorParallax, 10);
  }

  /// DODO POINTS PARALLAX

  if ($(".points-stuff").length) {
    dodoPointsScroll();
    $(window).scroll(dodoPointsScroll);
    var tempDelta;
    var pointsDelta = 0;



    function dodoPointsScroll() {
      tempDelta = $(window).scrollTop() > 150 ? $(window).scrollTop() - 150 : 0;
      console.log(tempDelta);
    };

    function dodoPointsParallax() {

      pointsDelta += (tempDelta - pointsDelta) / 2;

      $(".points-sticker-1 img").css(
        "transform",
        "translateY(" + pointsDelta / -5 + "px)"
      );
      $(".points-sticker-2 img").css(
        "transform",
        "translateY(" + pointsDelta / -8 + "px)"
      );
      $(".points-round-1 img").css(
        "transform",
        "translateY(" + pointsDelta / -15 + "px)"
      );
      $(".points-round-2 img").css(
        "transform",
        "translateY(" + pointsDelta / -10 + "px)"
      );
      $(".points-photo img").css(
        "transform",
        "translateY(" + pointsDelta / -18 + "px)"
      );

    };


    var cycler = setInterval(dodoPointsParallax, 5);
  }



  /// DODO FACES PARALLAX

  if ($(".dodo-faces").length) {
    dodoFacesScroll();
    $(window).scroll(dodoFacesScroll);
    var tempDelta;
    var pointsDelta = 0;



    function dodoFacesScroll() {
      tempDelta = $(window).scrollTop() > 11250 ? $(window).scrollTop() - 11250 : 0;
    };

    function dodoFacesParallax() {

      pointsDelta += (tempDelta - pointsDelta) / 2;

      $(".faces-1 img").css(
        "transform",
        "translateY(" + pointsDelta / -15 + "px)"
      );
      $(".faces-2-2 img").css(
        "transform",
        "translateY(" + pointsDelta / -5 + "px)"
      );
      $(".faces-3 img").css(
        "transform",
        "translateY(" + pointsDelta / -9 + "px)"
      );
    

    };


    var cycler = setInterval(dodoFacesParallax, 5);

  }

  // BOOK BEHAVIOUR LINES

  if ($(".behaviour-triggers").length) {
    $(".behaviour-trigger").click(function(e){
      e.preventDefault();
    });
    $(".behaviour-trigger").hover(function(e){
      $(`.behaviuor-gradient-line.line${$(this).data('line')}`).addClass('visible')
      

    }, function(e){
      $(`.behaviuor-gradient-line.line${$(this).data('line')}`).removeClass('visible')
    });
    
  }

  // BOOK CHAPTER 5 

  $(".plan-and-fact-toggler input:radio").on('change', function() {
    $('.week').removeClass('broken');
    if ($(this).val()-0) {
      $('.week').addClass('broken');
    }
    
  });

};



$(document).ready(ready);
$(document).on('page:load', ready);

