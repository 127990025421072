import $ from 'jquery';
import Snap from 'snapsvg';

const DEFAULT_DURATION_MS = 500;

export const animateEmoji = (emoji, newState, callback) => {
  const $emoji = $(emoji);
  const { parts, state } = $emoji.data();

  if (state === newState || !parts) return;

  $emoji.data('state', newState);

  parts.forEach(part => {
    const d = part.attr(newState);
    const duration = DEFAULT_DURATION_MS;

    part.animate({ d }, duration, window.mina.backout, callback);
  });
};

export const updateEmoji = (emoji, newState) => {
  const $emoji = $(emoji);
  const { parts, state } = $emoji.data();

  if (state === newState || !parts) return;

  $emoji.data('state', newState);

  parts.forEach(part => {
    part.attr('d', part.attr(newState));
  });
};

export const initEmoji = emoji => {
  const $emoji = $(emoji);
  const svg = emoji.querySelector('svg');

  if (!svg) return;

  const snap = Snap(svg);
  const parts = snap.selectAll('.animate');

  parts.forEach(part => {
    part.attr('d0', part.attr('d'));
  });

  $emoji.data('state', 'd0');
  $emoji.data('parts', parts);
  $emoji.data('timeout', null);
};
