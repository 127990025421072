import $ from 'jquery';
import likely from 'ilyabirman-likely';
import 'jquery-form/jquery.form';

var RIGHTPAD = 20;
var TOPPAD = 0;
var LEFTPAD = 40;
var BOTPAD = 40;
var BOXW = 220;
var BOXH = 340;
var ENDPAD = 30;
var FULLSIZE = 690;
var pageW = 0;
var cols = 0;
var maxcols = 4;
var sizeMsg = false;
var windowHeight = $(window).height();
var showDownTo = windowHeight * 2;

var niDelta = 0;
var tempDelta;

function xSort(a, b) {
  return a.x - b.x;
}

function ySort(a, b) {
  if (a.y == b.y) return a.x - b.x;
  else return a.y - b.y;
}

function dSort(a, b) {
  if (a.d == b.d) return a.x - b.x;
  else return a.d - b.d;
}

var shiftPosition = {
  CARDBORDER: 30,

  col_ar: [],
  pushed_ar: [],

  init: function() {
    LEFTPAD = $("#content").offset().left;
    TOPPAD = $("#content").offset().top;

    var itemsList = document.getElementById("content").childNodes.length;
    this.pushed_ar = [];
    var found = false;
    var pageW = this.getPageW();
    cols = Math.floor((pageW - LEFTPAD * 2) / (BOXW + RIGHTPAD));

    for (var i = cols; i > 0; i--) {
      if (i <= cols - 3) {
        var targetSelCol = i;
        break;
      }
    }

    window.onresize = function() {
      windowHeight = $(window).height();
      showDownTo = Math.max(windowHeight * 2, showDownTo);
      shiftPosition.init();
      start_preloading();
      shiftPagination();
    };

    $("#content")
      .children("article:not(.filtered-out)")
      .each(function(i, e) {
        shiftPosition.pushed_ar.push($(e).attr("id"));
      });
    ////////////////////// LOOP THROUGH THE LIST. FIND THE NEW SPOT TO OPEN IN
    /*for(i=0;i<itemsList;i++) {
				var thisItem = document.getElementById('content').childNodes[i];
				var bottom = thisItem.offsetTop + thisItem.offsetHeight;
				var thisCol = ((thisItem.offsetLeft-30)/(BOXW+BOTPAD))+1;
				this.pushed_ar.push(thisItem.id);
			}    */

    this.col_ar = [];
    if (!cols || cols < 3) cols = 3;
    this.col_ar.push({ x: 0, y: 0 }); //the imprint
    for (var i = 1; i < cols; i++) this.col_ar.push({ x: i, y: 0 });

    this.draw();
  },

  getPageW: function() {
    var fw;
    if (self.innerWidth) fw = self.innerWidth + 20;
    else if (document.documentElement && document.documentElement.clientWidth)
      fw = document.documentElement.clientWidth + 20;
    else if (document.body) fw = document.body.clientWidth + 20;
    return fw;
  },

  draw: function() {
    //this.clear();
    var ar = this.pushed_ar;
    var de;
    for (var o in ar) {
      o = ar[o];
      this.col_ar.sort(ySort);
      de = $("#" + o);
      if (de) {
        var W = Math.round(de.width() / BOXW);

        if (W > 1) this.drawWide(o);
        else {
          var c = this.col_ar[0];

          this.drawItem(o, c.x, c.y);
        }
      }
    }
  },

  drawWide: function(o) {
    var pc_ar = [];
    this.col_ar.sort(xSort);
    var d = $("#" + o);
    var W = Math.round(d.width() / BOXW);
    var uc = cols - W;
    /* HIDE STUFF THAT IS TOO BIG FOR THE SCREEN */
    if (uc < 0) {
      return;
    }

    /* RESIZE THE CONTENT TO A SMALLER SIZE */
    if (uc < 0) {
      uc = 1;
      W = 2;
    }

    for (var i = 0; i <= uc; i++) {
      pc_ar.push(this.col_ar[i]);
      var my = 0;
      var mi = null;
      for (var j = 0; j < W; j++) {
        var ty = this.col_ar[i + j].y;
        if (ty >= my) {
          my = ty;
          mi = i + j;
        }
      }
      var td = my;
      for (var j = 0; j < W; j++)
        td += Math.abs(this.col_ar[mi].y - this.col_ar[i + j].y);
      pc_ar[i].d = td;
      pc_ar[i].od = pc_ar[i].y - my;
    }
    pc_ar.sort(dSort);
    var x = pc_ar[0].x;
    var y = pc_ar[0].y;
    if (pc_ar[0].od < 0) y -= pc_ar[0].od;
    this.col_ar.sort(ySort);
    this.drawItem(o, x, y);
  },

  drawItem: function(o, x, y) {
    var d = $("#" + o);
    var W = Math.round(d.width() / BOXW);

    d.css({
      left: x * (BOXW + RIGHTPAD) + LEFTPAD + "px",
      top: y + TOPPAD + "px",
      position: "absolute",
      visibility: "visible"
    });

    if (W > 1) {
      this.col_ar.sort(xSort);
      for (var i = 0; i < W; i++)
        this.col_ar[x + i].y = y + d.height() + BOTPAD;
    } else this.col_ar[0].y = y + d.height() + BOTPAD;
  },

  findSameTop: function(myt, t) {
    if (myt == t) return true;
    else return false;
  },
  findProximity: function(myt, myb, b) {
    if (b >= myt && b <= myb) return true;
    else return false;
  },
  findClosest: function(p1, p2) {
    if (p1 >= p2) return p1;
    else return p2;
  },
  findSameCol: function(t1, t2, c1, c2) {
    if (c1 == c2) {
      if (t1 == t2) return true;
      else return false;
    } else return false;
  }
};

function shiftPagination() {
  var pageHeight = 0;
  var scrollPosition = getScrollHeight();
  var itemsList = document.getElementById("content").childNodes.length;

  for (var i = 0; i < itemsList; i++) {
    var thisItem = document.getElementById("content").childNodes[i];
    if (
      $(thisItem).hasClass("preloaded") ||
      $(thisItem).hasClass("loaded") ||
      $(thisItem).hasClass("preloading") ||
      $(thisItem).hasClass("loading")
    ) {
      var thisHeight = thisItem.offsetTop + thisItem.offsetHeight;
      if (thisHeight > pageHeight) pageHeight = thisHeight;
    }
  }
  $("#content").height(pageHeight - TOPPAD + "px");

  /*
	var pagination = document.getElementById('pagination');
	pagination.style.top = (pageHeight + 30) + 'px';
	pagination.style.left = '0px';
	pagination.style.position = 'absolute';
	pagination.style.visibility = "visible";
	*/
}

function getScrollHeight() {
  var y;
  if (self.pageYOffset) {
    y = self.pageYOffset;
  } else if (document.documentElement && document.documentElement.scrollTop) {
    y = document.documentElement.scrollTop;
  } else if (document.body) {
    y = document.body.scrollTop;
  }
  return parseInt(y) + _getWindowHeight();
}

function _getWindowHeight() {
   var frameWidth, frameHeight;
  if (self.innerWidth) {
    frameWidth = self.innerWidth;
    frameHeight = self.innerHeight;
  } else if (document.documentElement && document.documentElement.clientWidth) {
    frameWidth = document.documentElement.clientWidth;
    frameHeight = document.documentElement.clientHeight;
  } else if (document.body) {
    frameWidth = document.body.clientWidth;
    frameHeight = document.body.clientHeight;
  }
  return parseInt(frameHeight);
}

function arfind(ar, value) {
  for (i = 0; i < ar.length; i++) {
    if (ar[i] == value) {
      spot = i;
      break;
    }
  }
  return spot;
};
function load_all() {
  $('article.preloaded').each(function () {
    $(this).removeClass('preloaded').addClass('loading');

    $(this).find('img').each(function () {
      if (!$(this).attr('src') || $(this).attr('src').length < 1) {
        $(this).attr('src', $(this).attr('data-src') + (window.navigator.userAgent.indexOf("MSIE ") >= 0 || navigator.userAgent.match(/Trident.*rv\:11\./) ? '?v=' + Math.random() : '')).addClass('loading').one('load', function () {
          $(this).removeClass('loading');
          if ($(this).parents('article').find('img.loading').length < 1)
            $(this).parents('article').removeClass('loading').addClass('loaded');
        });
        if (this.complete)
          $(this).load();
      }
    });

    if ($(this).parents('article').find('img.loading').length < 1)
      $(this).parents('article').removeClass('loading').addClass('loaded');
  });
}

function preload($el) {
  var $img = $el.find('img').first();

  if ($img.length > 0) {
    $el.addClass('preloading');
    $img.attr('src', $img.attr('data-src') + '?v=' + (window.navigator.userAgent.indexOf("MSIE ") >= 0 || navigator.userAgent.match(/Trident.*rv\:11\./) ? Math.random() : '0'))
      .one('load', function () {
        $el.removeClass('preloading').addClass('preloaded');
        if ($('.preloading').length < 1 && !(/(?:iphone|ipod|ipad)/gi).test(navigator.appVersion))
          load_all();
      });
    if ($img[0].complete)
      $img.load();
    shiftPagination();
  }
}

function start_preloading() {
  $("#content")
    .children(
      "article:not(.filtered-out,.loaded,.loading,.preloading,.preloaded)"
    )
    .each(function(i, e) {
      if ($(this).position().top < showDownTo) {
        if (
          $(this)
            .find("img")
            .length > 0
        )
          preload($(this));
        else $(this).addClass("loaded");
      }
    });
}

var ready = function () {
  // if (window.location.search.length > 1) {
  //   var c = window.location.search.substring(1).replace('=', '');
  //   if ($("article." + c).length > 0) {
  //     $("article:not(." + c + ")")
  //       .addClass("filtered-out")
  //       .siblings("article." + c)
  //       .removeClass("filtered-out");
  //     if ($("#menu a." + c).length > 0) $("#menu a." + c).addClass("active");
  //     else {
  //       var name = $('.authors-main a[href="/?' + c + '"]').text();
  //       $('.authors-main a[href="/?' + c + '"]').replaceWith(
  //         "<span>" + name + "</span>"
  //       );
  //     }
  //   }
  // }

	// $('article .image-slider').each(function () {
	//   var $imgs = $(this).find('img');
	//   if ($imgs.length == 1 && $imgs.attr('data-count') * 1 > 1) {
	//     var url = $imgs.attr('data-src');
	//     for (var i = $imgs.attr('data-count'); i > 1; i--) {
	//       var nurl = url.replace(/([A-Za-z0-9_\-%]+)\d\.(\w)/gi, "$1" + i + ".$2");
	//       $imgs.after('<img data-src="' + nurl + '" height="' + $imgs.attr('height') + '"/>');
	//     }
	//   }
  // });

  // $("#info-trigger").click(function() {
  //   $("#triggered-info").toggle();
  //   if ($("article").filter(".promo").length) shiftPosition.init();
  // });

  // $("#content")
  //   .on("mousemove", ".loaded .image-wrapper", function(e) {
  //     var imgcnt = $(this)
  //       .find("img")
  //       .length;
  //     var mywidth = $(this).width();
  //     var $slider = $(this).find(".image-slider");
  //     var totalwidth = $slider.width();

  //     var imgwidth = $(this)
  //       .find("img")
  //       .width();
  //     var imgind = Math.ceil(-$slider.css("left") / imgwidth);
  //     var newind = Math.floor(
  //       (e.pageX -
  //         $(this)
  //           .parents("article")
  //           .offset().left) /
  //         (mywidth / imgcnt)
  //     );

  //     if (imgind != newind && $slider.queue("fx").length == 0) {
  //       $slider.css("left", -(newind * imgwidth));
  //       $(this)
  //         .siblings(".image-indicator")
  //         .width(mywidth)
  //         .find(".image-indicator-active")
  //         .width(mywidth / imgcnt)
  //         .css("left", (newind * mywidth) / imgcnt);
  //     }
  //   })
  //   .mousemove()
  //   .on("mouseenter", ".loaded .image-wrapper", function() {
  //     $(this)
  //       .siblings(".image-indicator")
  //       .fadeIn(10);
  //   })
  //   .on("mouseleave", ".loaded .image-wrapper", function() {
  //     $(this)
  //       .siblings(".image-indicator")
  //       .fadeOut("fast");
  //   })
  //   .mouseleave();

  // if ($("article").filter(".promo").length) {
  //   shiftPosition.init();
  //   shiftPagination();
  // }

  // $("article").each(function() {
  //   if (
  //     $(this)
  //       .find(".promo-text a")
  //       .length > 0
  //   ) {
  //     $(this)
  //       .find(".image-wrapper")
  //       .attr(
  //         "href",
  //         $(this)
  //           .find(".promo-text a")
  //           .first()
  //           .attr("href")
  //       );
  //     $(this)
  //       .find(".promo-text a")
  //       .first()
  //       .hover(
  //         function() {
  //           $(this)
  //             .parents("article")
  //             .addClass("hovered");
  //         },
  //         function() {
  //           $(this)
  //             .parents("article")
  //             .removeClass("hovered");
  //         }
  //       );
  //   }
  // });

  /*
	$('#menu a').click(function(){
		$('#menu a').removeClass('active');

		$('article:not(.' + $(this).attr('class') + ')').addClass('filtered-out').siblings('article.' + $(this).attr('class')).removeClass('filtered-out');
		$(this).addClass('active');

		shiftPosition.init();
        shiftPagination();

        return false;

	});*/

  start_preloading();

  $(window).scroll(function() {
    if ($(window).scrollTop() + windowHeight - 50 > showDownTo) {
      showDownTo = Math.max(
        $(window).scrollTop() + windowHeight * 2,
        showDownTo
      );
      start_preloading();
    }
  });

  /*
	    if ((/(?:iphone|ipod|ipad).*os 5/gi).test(navigator.appVersion)) {
            $window.bind("pageshow", function(event) {
                if (event.originalEvent && event.originalEvent.persisted) {
                    elements.each(function() {
                        $(this).trigger("appear");
                    });
                }
            });
        }
    */

  $("form.registration-form").submit(function() {
    if (
      $(this)
        .find('input[name="dfwas"]')
        .val().length < 1 &&
      $(this)
        .find('input[name="erqbzd"]')
        .val().length < 1
    ) {
      $(this)
        .find("label")
        .first()
        .addClass("red");
      return false;
    }

    if (
      $(this)
        .find('input[type="radio"]')
        .length > 0 &&
      $(this)
        .find('input[type="radio"]:checked')
        .length < 1
    ) {
      $(this)
        .find(".role-choise")
        .first()
        .prev()
        .addClass("red");
      return false;
    }

    $(this).ajaxSubmit({
      success: function(data) {
        $("form.registration-form").html(data);
      }
    });
    return false;
  });

  $(".js-reduce").click(function() {
    var url = $(this).attr("url");
    var $this = $(this);

    $.ajax({
      url: $(this).attr("href"),
      type: "get",
      success: function(data) {
        $this.prev("div").html(data);
      }
    });
    return false;
  });

  $(".js-scroll-to-form").click(function() {
    $("html, body").animate(
      {
        scrollTop: $("form.registration-form").offset().top
      },
      500
    );
  });

  $("a.image-wrapper, a.widget-link").hover(
    function() {
      $(this)
        .siblings(".promo-text")
        .find("a")
        .first()
        .addClass("hover");
    },
    function() {
      $(this)
        .siblings(".promo-text")
        .find("a")
        .first()
        .removeClass("hover");
    }
  );

  function upload(file, $progress_container) {
    var xhr = new XMLHttpRequest();

    // обработчик для закачки
    xhr.upload.onprogress = function(event) {
      $progress_container.html(
        '<div class="progress"><div style="width:' +
          Math.round((100 * event.loaded) / event.total) +
          '%;"></div></div>'
      );
    };

    // обработчики успеха и ошибки
    // если status == 200, то это успех, иначе ошибка
    xhr.onload = xhr.onerror = function() {
      if (this.status == 200) {
        $progress_container.html('<img src="' + this.response + '"/>');
        $progress_container
          .siblings(".js-file-upload")
          .find('input[type="hidden"]')
          .val(this.response);
        $progress_container
          .siblings(".js-file-upload")
          .find("span")
          .html("Заменить картинку...");

        if ($progress_container.parents(".new_record").length > 0) {
          $progress_container
            .parents(".new_record")
            .find(".js-rec-text")
            .hide();
          $progress_container
            .parents(".new_record")
            .find(".js-rec-wide")
            .show();
        }
      } else {
        $progress_container.html("<p>Ошибка: " + this.status + "</p>");
      }
    };

    xhr.open("POST", "/upload", true);
    var formData = new FormData();
    formData.append("upload", file);
    xhr.send(formData);
  }

  $(".js-file-upload input").change(function() {
    if (this.files && this.files[0]) {
      /*var reader = new FileReader();
			var ff = this.files[0];

	        $this = $(this);

	        reader.onload = function (e) {
	        	if(e.total > 1024 * 1024 * 10){
		        	$this.parents('.form-item').find('.js-file-upload-preview').html('<p>Пожалуйста, не больше 10 мб!</p>').addClass('loaded');
	        	} else {
					upload(ff)
		        	//$this.parents('.form-item').find('.js-file-upload-preview').html('<img src="'+e.target.result+'"/>').addClass('loaded');
		        }
	        }

	        reader.readAsDataURL(this.files[0]);*/
      if (this.files[0].size > 1024 * 1024 * 10) {
        $(this)
          .parents(".form-item")
          .find(".js-file-upload-preview")
          .html("<p>Пожалуйста, не больше 10 мб!</p>")
          .addClass("loaded");
      } else {
        upload(
          this.files[0],
          $(this)
            .parents(".form-item")
            .find(".js-file-upload-preview")
            .addClass("loaded")
        );
        //$this.parents('.form-item').find('.js-file-upload-preview').html('<img src="'+e.target.result+'"/>').addClass('loaded');
      }
      this.files[0].size;
    }
  });
};

$(document).ready(ready);
$(document).on('page:load', ready);
likely.initiate();
