import axios from 'axios';

const TIMEOUT_MS = 120000; // 2 min
const DELAY_MS = 2000;
const START = Date.now();

const doPoll = async () => {
  try {
    const response = await axios.get(
      `/api/billing/status?token=${PollConfig.token}`
    );
    const duration = Date.now() - START;

    if (
      response.data.status !== 'pending' &&
      response.data.status !== 'initialized'
    ) {
      window.location = response.data.redirectUrl || PollConfig.returnUrl;
    } else if (duration < TIMEOUT_MS) {
      setTimeout(doPoll, DELAY_MS);
    } else {
      window.location = PollConfig.returnUrl;
    }
  } catch (e) {
    window.location = PollConfig.returnUrl;
  }
};

export default doPoll;
