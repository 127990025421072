import axios from 'axios';

const validateUser = async email => {
  const encodedEmail = encodeURIComponent(email);
  const url = `/api/users/validate?email=${encodedEmail}`;

  return axios.get(url);
};

const validatePromocode = async (code, kind) => {
  const url = `/api/promocodes/validate?code=${code}&kind=${kind}`;

  return axios.get(url);
};

const purchase = async data => {
  const url = '/api/billing/purchase';

  return axios.post(url, data);
};

export default {
  validateUser,
  validatePromocode,
  purchase,
};
