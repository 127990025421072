import $ from 'jquery';
import { initEmoji, updateEmoji, animateEmoji } from './emoji';

const ACTIVE = 1;
const WAITING = 2;
const HIDDEN = 3;

const EMOJI_STATES = [
  [HIDDEN, WAITING, WAITING, ACTIVE],
  [WAITING, ACTIVE, WAITING, WAITING],
  [WAITING, WAITING, ACTIVE, WAITING],
  [ACTIVE, WAITING, WAITING, HIDDEN],
];

const DEFAULT_EMOJI_STATES = EMOJI_STATES[0];

const showEmoji = $emoji => {
  if ($emoji.hasClass('--hidden')) {
    $emoji.removeClass('--hidden');
    updateEmoji($emoji, 'd0');
  }
};

const hideEmoji = $emoji => {
  if (!$emoji.hasClass('--hidden')) {
    $emoji.addClass('--hidden');
    animateEmoji($emoji, 'd0');
  }
};

const setEmojiWaiting = $emoji => {
  animateEmoji($emoji, 'd1');
};

const setEmojiActive = $emoji => {
  animateEmoji($emoji, 'd2');
};

const setEmojiState = (emoji, emojiState) => {
  const $emoji = $(emoji);

  switch (emojiState) {
    case ACTIVE:
      showEmoji($emoji);
      setEmojiActive($emoji);
      break;
    case WAITING:
      showEmoji($emoji);
      setEmojiWaiting($emoji);
      break;
    case HIDDEN:
      hideEmoji($emoji);
      break;
    default:
      hideEmoji($emoji);
  }
};

const setEmojiStates = ($emojis, emojiStates) => {
  $emojis.each((index, emoji) => setEmojiState(emoji, emojiStates[index]));
};

$(() => {
  $('.smartcalls-widget').each((widgetIndex, widget) => {
    const $widget = $(widget);
    const $emojis = $widget.find('.emoji');
    const $background = $widget.find('.widget-background');

    $emojis.each((emojiIndex, emoji) => {
      initEmoji(emoji);
    });

    $background.on('mousemove touchmove', event => {
      const width = $background.width();
      const offsetX = $background.offset().left;
      const pageX = event.originalEvent.touches
        ? event.originalEvent.touches[0].pageX
        : event.pageX;
      const index = Math.floor(
        (pageX - offsetX) / (width / EMOJI_STATES.length)
      );
      const emojiStates = EMOJI_STATES[index] || DEFAULT_EMOJI_STATES;

      setEmojiStates($emojis, emojiStates);
    });

    setEmojiStates($emojis, DEFAULT_EMOJI_STATES);
  });
});
