const applyPromocode = (cart, promocode, splitVariantByPrice = true) => {
  const promocodeApplication = {};

  if (!promocode || (promocode.singleUse && promocode.applied)) {
    return cart.map(item => ({
      ...item,
      price: +item.price,
      originalPrice: +item.price,
      totalPrice: +item.price * item.quantity,
    }));
  }

  return cart.reduce((acc, item) => {
    const { price, quantity } = item;
    const originalPrice = +price;

    const product = (promocode.Products || []).find(
      ({ id }) => +id === +item.productId
    );

    const isFirstOnlyApplied =
      product && promocode.firstOnly && promocodeApplication[product.id];

    // If promocode isn't applicable to this product just use the original price.
    // Also take into account invalid quantity value and already applied
    // first-only promocodes.
    if (!product || quantity < 0 || isFirstOnlyApplied) {
      return acc.concat({
        ...item,
        originalPrice,
        price: originalPrice,
        totalPrice: originalPrice * Math.max(quantity, 0),
      });
    }

    const discountedPrice =
      +(product.ProductPromocodes || {}).discountedPrice || originalPrice;

    // If promocode isn't first-only use the discounted price.
    if (!promocode.firstOnly) {
      return acc.concat({
        ...item,
        originalPrice,
        price: discountedPrice,
        totalPrice: discountedPrice * quantity,
      });
    }

    // Mark this product as discounted already.
    promocodeApplication[product.id] = true;

    // If promocode is first-only and quantity is either 0 or 1 use the discounted price.
    if (promocode.firstOnly && quantity <= 1) {
      return acc.concat({
        ...item,
        originalPrice,
        price: discountedPrice,
        totalPrice: discountedPrice * quantity,
      });
    }

    // If promocode is first-only and we want to keep product variant grouped
    // use the discounted price for the first item only in total price.
    if (promocode.firstOnly && !splitVariantByPrice) {
      return acc.concat({
        ...item,
        originalPrice,
        price: originalPrice,
        totalPrice: discountedPrice + originalPrice * (quantity - 1),
      });
    }

    // If promocode is first-only and we want to split product variant by price
    // use the discounted price for the first item only and group the rest
    // in a separate cart item.
    if (promocode.firstOnly && splitVariantByPrice) {
      return acc.concat([
        {
          ...item,
          originalPrice,
          quantity: 1,
          price: discountedPrice,
          totalPrice: discountedPrice,
        },
        {
          ...item,
          originalPrice,
          quantity: quantity - 1,
          price: originalPrice,
          totalPrice: originalPrice * (quantity - 1),
        },
      ]);
    }

    return acc;
  }, []);
};

const isOfProductVariant = (product, variant) => {
  const productId = product.id || product.productId;
  const variantProductId = variant.id || variant.productId;

  return (
    productId === variantProductId &&
    Object.keys(variant).every(key => product[key] === variant[key])
  );
};

const calculateCartTotal = cart => {
  const values = cart.reduce ? cart : Object.values(cart);

  return values.reduce((res, item) => res + item.totalPrice, 0);
};

module.exports = {
  applyPromocode,
  calculateCartTotal,
  isOfProductVariant,
};
