import $ from 'jquery';
import Cookies from 'js-cookie';

$('.switcher-icon a').click(event => {
  event.preventDefault();

  const $link = $(event.currentTarget);
  const href = $link.attr('href');
  const locale = $link.data('locale');
  const domain = (window.location.hostname.match(/[^.]*\.[^.]*$/g) || [])[0];

  Cookies.set('_int_locale', locale, { domain, expires: 365 });

  window.location.href = href;
});
