const { Input } = require("hammerjs");

document.addEventListener('DOMContentLoaded', () => {
  const collectionLinkCard = document.querySelectorAll('.shop-item');
  
  if (collectionLinkCard) {
    collectionLinkCard.forEach(card => {
      let isPlayed = false;
      let isTextEdited = false;
      const cardInput = card.querySelectorAll('h2 .custom-word input');
      
      const typeWriter = (elem, text, attribute=true) => {
        const letters = text;
        let i = 0;
        let filledText = '';
  
        const playAnim = () => {
          setTimeout(() => {
            filledText += letters.charAt(i)
            if (attribute) {
              elem.setAttribute('data-after', filledText)
            } else {
              elem.textContent = filledText;
            }
            
            if (i > letters.length) {
              return
            } else {
              i++
            }
            playAnim()
          }, 100)
        }

        if (!isTextEdited) {
          if (attribute) {
            elem.setAttribute('data-after', '')
          } else {
            elem.textContent = '';
          }
          playAnim();
          isPlayed = true;
        }
      }

      const collectionAnimation = (firstWord, secondWord) => {
        if (!card.classList.contains('sleep') && !isPlayed) {
          typeWriter(firstWord, 'ая')
          setTimeout(() => {typeWriter(secondWord, 'коллекция')}, 250)
        } else if (card.classList.contains('sleep') && isPlayed) {
          isPlayed = false;
        }
      }

      const customizableAnimation = (firstWord, secondWord, tshirtEnding, tshirtSecondWord) => {
        if (!card.classList.contains('sleep') && !isPlayed) {
          const firstWordText = firstWord.querySelector('input').getAttribute('value');
          const secondWordText = secondWord.querySelector('input').getAttribute('value');

          typeWriter(firstWord, firstWordText)
          
          setTimeout(() => {
            typeWriter(tshirtEnding, firstWordText, attribute=false)
          }, 100)
          
          setTimeout(() => {
            typeWriter(secondWord, secondWordText)
            typeWriter(tshirtSecondWord, secondWordText, attribute=false)
          }, 250)
        } else if (card.classList.contains('sleep') && isPlayed) {
          isPlayed = false;
        }
      }
  
      if (!card.parentNode.classList.contains('goods')) {
        const buyButton = card.querySelectorAll('button');
        
        const generateRandomNumber = (min, max) =>  {
          return Math.floor(Math.random() * (max - min) + min);
        };

        buyButton.forEach(el => {
          card.addEventListener('mouseenter', () => {  
            const verticalPath = generateRandomNumber(-20,20); 
            el.style.transform = `
              translateY(${verticalPath}px) 
              rotate(${generateRandomNumber(-15,15)}deg)
            `;
  
            if (verticalPath <= 10) {
              el.style.transitionDuration = '1s';
            } else {
              el.style.transitionDuration = '2s';
            }
          })
          
          card.addEventListener('mouseleave', () => {
            el.style.transform = 'none';
            el.style.transitionDuration = '0.75s';
          })
        })
      }
      
      cardInput.forEach(input => {
        input.oninput = () => {
          isTextEdited = true;
        }
        
        input.onfocus = () => {
          card.classList.add('hide-input-animation');
        }
        
        input.onblur = () => {
          if (!isTextEdited) {
            card.classList.remove('hide-input-animation');
          }
        }
      })
      
      if (card.classList.contains('collection')) {
        const firstWord = card.querySelector('h2 span:first-child');
        const secondWord = card.querySelector('h2 span:last-child');

        collectionAnimation(firstWord, secondWord)
        
        window.addEventListener('scroll', () => {
          collectionAnimation(firstWord, secondWord)
        });
      } else if (card.classList.contains('customizable') && !card.classList.contains('text-edited')) {
        const firstWord = card.querySelector('h2 .custom-word:first-of-type');
        const secondWord = card.querySelector('h2 .custom-word:last-of-type');
        const tshirtEnding = card.querySelector('.tshort-text span.ending');
        const tshirtSecondWord = card.querySelector('.tshort-text span.second-word');

        customizableAnimation(firstWord, secondWord, tshirtEnding, tshirtSecondWord)
        
        window.addEventListener('scroll', () => {
          customizableAnimation(firstWord, secondWord, tshirtEnding, tshirtSecondWord)
        });
      }
    })
  }
})  