import api from '../api';
import doPoll from '../poll';
import animateValue from '../animate-value';

import { applyPromocode, calculateCartTotal } from '../../shared/billing';

import { validateEmail, validatePhone } from './validators';
import authorize from './auth';
import removeError from './form-helpers';

import renderCart from './weird-collection';

const updatePrices = (discountedPrice) => {

  const nodes = document.querySelectorAll('.animated-price');

  if (!nodes) return;
  
  nodes.forEach(node => {
    animateValue(
      node,
      discountedPrice,
      450
    );
  });
};

const updateCart = (kind, promocode) => {
  if (kind === 'weird-collection') {
    renderCart(promocode);
  }
};


const initializePromocode = purchaseFormWrapper => {
  const purchaseWrapper = document.querySelector('.purchase-form');
  const promoLink = purchaseWrapper.querySelector('.promocode-link');
  const formWrapper = purchaseWrapper.querySelector('.promocode-form');
  const form = formWrapper.firstChild;

  
  promoLink.addEventListener('click', event => {
    event.preventDefault();
    formWrapper.style.display = 'block';
    promoLink.classList.add('field-opened');
  });
  
  form.addEventListener('submit', async event => {
    event.preventDefault();
    const { id: productId } = purchaseWrapper.dataset;
    const { kind } = purchaseWrapper.dataset;
    const { value } = event.target.promocode;
    
    // Clear all errors
    formWrapper.classList.remove('wrong-promocode');
    form.classList.remove('error');

    try {
      const response = await api.validatePromocode(value, kind);

      if (response.status === 200) {
        const currentKind = response.data.Products[0].kind;

        if (productId) {
          updatePrices(response.data.Products[0].ProductPromocodes.discountedPrice);
        } else {
          const cart = JSON.parse(localStorage.getItem(kind));

          Object.keys(cart).forEach(key => {
            const product = productsOfCurrentKind.find(({ id }) => id === cart[key].productId);
            cart[key].price = product ? product.priceInt : 0;
          });

          const cartWithPromocode = applyPromocode([...Object.values(cart)], response.data);
          const totalCartPrice = calculateCartTotal(cartWithPromocode);

          updatePrices(totalCartPrice);
        }

        purchaseFormWrapper.classList.add('correct-promocode');
        purchaseWrapper.dataset.promocode = JSON.stringify(response.data || {});

        updateCart(kind, response.data);
      } else if (value.length === 0) {
        form.classList.add('error');
      } else {
        throw new Error('No such promocode');
      }
    } catch (e) {
      formWrapper.classList.add('wrong-promocode');
    }
  });

  const promoInput = form.elements.promocode;

  promoInput.addEventListener('keydown', () =>
    removeError(formWrapper, 'wrong-promocode')
  );
  promoInput.addEventListener('focusout', () =>
    removeError(formWrapper, 'wrong-promocode')
  );
};

const initializePurchase = (purchaseFormWrapper) => {
  const purchaseWrapper = document.querySelector('.purchase-form');
  const purchaseButton = document.querySelector('.purchase-button');
  const purchaseForm = purchaseFormWrapper.querySelector('#purchase-form');

  
  purchaseForm.addEventListener('submit', async event => {
    event.preventDefault();
    const { id: productId } = purchaseWrapper.dataset;

    const inputs = event.target.querySelectorAll('input');
    const isEmptyField = !![...inputs].filter(
      field => field.name !== 'cities-list' && field.value.length === 0
    ).length;

    // продажи приостановлены
    if (purchaseWrapper.getAttribute('data-kind') === 'weird-collection') {
      return;
    } else {
      if (
        isEmptyField ||
        !validateEmail(event.target.email.value) ||
        !validatePhone(event.target.phone)
      ) {
        purchaseForm.classList.add('error');
        setTimeout(() => purchaseForm.classList.remove('error'), 700);
        return;
      }
  
      const { promocode, kind } = purchaseWrapper.dataset;
      const promocodeName = JSON.parse(promocode || "{}").name;
  
      purchaseButton.classList.add('in-progress');
      purchaseButton.disabled = true;
  
      const customer = { email: event.target.email.value };
  
      if (event.target.name) customer.name = event.target.name.value;
      if (event.target.phone) customer.phone = event.target.phone.value;
      if (event.target.address) customer.address = event.target.address.value;
      if (event.target.packing) customer.packing = event.target.packing.value;
  
      const payload = {
        customer,
        promocodeData: { name: promocodeName, kind },
      };
  
      if (productId) {
        payload.cartData = [{ productId, quantity: 1 }];
      } else {
        const cart = localStorage.getItem(kind);
  
        payload.cartData = Object.values(JSON.parse(cart));
      }

      try {
        const response = await api.purchase(payload);
  
        if (response.data && response.data.redirectUrl) {
          window.location = response.data.redirectUrl;
        } else {
          throw new Error('Purchase request failed: redirectUrl is missing.');
        }
      } catch (error) {
        const { data = {} } = error.response || {};
        purchaseButton.disabled = false;
  
        if (data.name === 'AuthEmailExistsError') {
          purchaseFormWrapper.classList.add('used-email');
        }
      };
    }
  });

  const input = purchaseFormWrapper.querySelector('input');

  input.addEventListener('keydown', () =>
    removeError(purchaseFormWrapper, 'used-email')
  );
  input.addEventListener('focusout', () =>
    removeError(purchaseFormWrapper, 'used-email')
  );
};

document.addEventListener('DOMContentLoaded', () => {
  const purchaseFormWrapper = document.querySelector('.purchase-form-wrapper');

  if (purchaseFormWrapper) {
    initializePromocode(purchaseFormWrapper);
    initializePurchase(purchaseFormWrapper);
  }

  const loginForm = document.querySelector('form.login-form');

  if (loginForm) {
    authorize(loginForm);
  }

  if (document.location.pathname.includes('/billing/poll/')) {
    doPoll();
  }
});
