// numbers count animation from https://codepen.io/chriscoyier/pen/xxVBqEg
function animateValue(target, end, duration) {
  const start = +target.dataset.price;
  const locale = target.dataset.locale;

  if (!start || !locale) {
    target.textContent = +end
    return
  }
  let startTimestamp = null;
  const step = timestamp => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    const result = Math.floor(progress * (end - start) + start);
    target.dataset.price = result
    target.textContent = Number(result).toLocaleString(target.dataset.locale)
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
};

export default animateValue;
