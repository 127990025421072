import $ from 'jquery';
import Hammer from 'hammerjs';

$(window).on('load resize', () => {
  const $stretch = $('.interactive-promo .promo-container .font-var-settings');
  const $noStretch = $('.interactive-promo .promo-container .no-stretch');
  const root = document.querySelector('.interactive-promo .promo-container');
  const $root = $('.interactive-promo .promo-container');

  const throttle = (func, limit) => {
    let inThrottle = false;

    return function throttledFunc(...args) {
      if (!inThrottle) {
        func.apply(this, args);
        inThrottle = true;

        setTimeout(() => {
          inThrottle = false;
        }, limit);
      }
    };
  };

  if (root !== null) {
    const rootTopOffset = root.getBoundingClientRect().top + window.scrollY;
    const rootLeftOffset = root.getBoundingClientRect().left + window.scrollX;
    let containerHeight = $root.innerHeight();
    let containerWidth = $root.innerWidth();
    const dotWidth = $('.dot').innerWidth();

    $root.on(
      'mousemove touchmove',
      throttle(e => {
        if (e.originalEvent.touches) {
          var rootX = e.originalEvent.touches[0].pageX;
          var rootY = e.originalEvent.touches[0].pageY;
        } else {
          var rootX = e.clientX;
          var rootY = e.pageY;
        }
        
        e.preventDefault();
        $('.mobile-only-hint').fadeOut(250);
  
        //формулы показывают значения шкал от 200 до 999 и 700 до 120
        const textWeight = ((containerHeight + rootTopOffset - rootY) / (containerHeight/(700 - 120)) + 120).toFixed(0);
        const textWidth = ((rootX - rootLeftOffset) / (containerWidth/(999 - 200)) + 200 + 1).toFixed(0);

        const offsetX = rootX - rootLeftOffset;
        const offsetY = -(rootY - rootTopOffset - 5);
  
        $stretch.attr(
          'style',
          `--text-wght: ${textWeight}; --text-wdth: ${textWidth};`
        );
  
        $noStretch.attr('style', `--text-wght: ${textWeight};`);
  
        //проверка границ оффсетов
        function checkOffsetX() {
          if (offsetX >= (containerWidth - dotWidth)) {
            return (containerWidth - dotWidth);
          } else if (offsetX <= 0) {
            return 0
          } else {
            return offsetX;
          }
        }
        
        function checkOffsetY() {
          if (offsetY <= -(containerHeight - dotWidth)) {
            return -(containerHeight - dotWidth);
          } else if (offsetY >= 0) {
            return 0
          } else {
            return offsetY;
          }
        }

        $('.interactive-promo .range-slider.horizontal .dot').css(
          'transform',
          `translateX(${checkOffsetX()}px)`
        );
  
        $('.interactive-promo .range-slider.vertical .dot').css(
          'transform',
          `translateX(${checkOffsetY()}px)`
        );
  
        //чтобы не ломались цифры у шкалы
        $('.interactive-promo .range-slider.horizontal span').html(textWidth >= 200 ? textWidth : 200);
        $('.interactive-promo .range-slider.horizontal span').html(textWidth <= 999 ? textWidth : 999);
        $('.interactive-promo .range-slider.vertical span').html(textWeight <= 700 ? textWeight : 700);
      }, 0)
    );
  }
  

  $('.table-cell.1').addClass('extrabold');
  $('.table-letter').show();

  // показываю и прячу огромную букву на фоне, когда таблица в экране
  const charTable = [].slice.call(document.querySelectorAll('.multilanguage-char-table'));

  if ("IntersectionObserver" in window) {
    let charTableObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry){
        if (entry.isIntersecting) {
          $('.table-letter').show();
          
          // внутри обсервера, чтобы до этого не считалась
          if (window.matchMedia("(max-width: 768px)").matches) {
            $('.table-cell.1').removeClass('extrabold');
            $('.table-cell.1').addClass('highlight');

            window.addEventListener('scroll', function(){
              const charTableHeight = document.querySelector('.multilanguage-char-table').offsetHeight + window.innerHeight; // 600 — высота, на которую буква выступает перед и после таблицы
              const charTableCount = $('.multilanguage-char-table .table-cell').length;
              const charTableOffset = -document.querySelector('.multilanguage-char-table').getBoundingClientRect().top + window.innerHeight;
  
              if (charTableOffset >= 0) {
                const calculatedCellNumber = (charTableOffset / (charTableHeight / charTableCount)).toFixed(0);
                const calculatedCell = $(`.table-cell.${calculatedCellNumber}`);
                $('.table-letter').html(calculatedCell.text());
                $('.table-cell.1').removeClass('highlight');
                setTimeout(() => {calculatedCell.removeClass('highlight')}, 300);
                calculatedCell.addClass('highlight');
              }
            })
          }

        } else {
          $('.table-letter').hide();
        }
      })
    }, {
      rootMargin: `0px 0px 0px 0px`,
    });

    charTable.forEach(function(entry){
      charTableObserver.observe(entry);
    })
  }

  if (window.matchMedia("(min-width: 769px)").matches) {
    $('.table-cell').on("mouseover", function() {
      const letter = $(this).text();
      $('.table-letter').html(letter);
      $('.table-cell.1').removeClass('extrabold');
      $(this).addClass('extrabold');
    })
      
    $('.table-cell').on("mouseout", function() {
      setTimeout(() => {$(this).removeClass('extrabold')}, 100);
    })
  }

})

$('.multilanguage-char-table .fixed-container').css('height', $('.multilanguage-char-table').innerHeight() + 1050);

// переключатели

$('.ss-module').on('click', function () {
  toggleSsSwitch();
});
$('#alt-features-ss').on('change', function () {
  toggleSsSwitch();
});


$('.ligatures-module').on('click', function () {
  toggleLigaturesSwitch();
});
$('#alt-features-ligatures').on('change', function () {
  toggleLigaturesSwitch();
});


$('.promocode-module').on('click', function () {
  toggleTnumSwitch();
});
$('#alt-features-tnum').on('change', function () {
  toggleTnumSwitch();
});


$('.menu-module').on('click', function () {
  toggleFracSwitch();
});
$('#alt-features-frac').on('change', function () {
  toggleFracSwitch();
});


$('.digits-module').on('click', function () {
  toggleDigitsSwitch();
});
$('#alt-features-digits').on('change', function () {
  toggleDigitsSwitch();
});


function toggleSsSwitch() {
  if ($('#alt-features-ss').prop('checked') === false) {
    $('.ss-module').attr('style', '--ss00-switch: on;');
    $('#alt-features-ss').prop('checked', true);
  } else {
    $('.ss-module').attr('style', '--ss00-switch: off;');
    $('#alt-features-ss').prop('checked', false);
  }
}

function toggleLigaturesSwitch() {
  if ($('#alt-features-ligatures').prop('checked') === false) {
    $('.ligatures-module').attr('style', 'font-variant-ligatures: common-ligatures;');
    $('#alt-features-ligatures').prop('checked', true);
  } else {
    $('.ligatures-module').attr('style', 'font-variant-ligatures: none;');
    $('#alt-features-ligatures').prop('checked', false);
  }
}

function toggleTnumSwitch() {
  if ($('#alt-features-tnum').prop('checked') === false) {
    $('.promocode-module').attr('style', '--ss00-switch: on;');
    $('#alt-features-tnum').prop('checked', true);
  } else {
    $('.promocode-module').attr('style', '--ss00-switch: off;');
    $('#alt-features-tnum').prop('checked', false);
  }
}

function toggleFracSwitch() {
  if ($('#alt-features-frac').prop('checked') === false) {
    $('.menu-module').attr('style', '--ss00-switch: on;');
    $('.menu-module').find('.frac').attr('style', '--frac-switch: on')
    $('#alt-features-frac').prop('checked', true);
  } else {
    $('.menu-module').attr('style', '--ss00-switch: off;');
    $('.menu-module').find('.frac').attr('style', '--frac-switch: off')
    $('#alt-features-frac').prop('checked', false);
  }
}

function toggleDigitsSwitch() {
  if ($('#alt-features-digits').prop('checked') === false) {
    $('.digits-module').attr('style', '--ss00-switch: on; --tnum-switch: on; font-variant-ligatures: common-ligatures;');
    $('.digits-module').find('.frac').attr('style', '--frac-switch: on')
    $('.digits-module').find('.sups').attr('style', '--sups-switch: on')
    $('.digits-module').find('.frac.sups').attr('style', '--sups-switch: on; --frac-switch: on;')
    $('.digits-module').find('.superscript').addClass('sups').attr('style', '--sups-switch: on');
    $('#alt-features-digits').prop('checked', true);
  } else {
    $('.digits-module').attr('style', '--ss00-switch: off; --tnum-switch: off; font-variant-ligatures: none;');
    $('.digits-module').find('.frac').attr('style', '--frac-switch: off')
    $('.digits-module').find('.sups').attr('style', '--sups-switch: off')
    $('.digits-module').find('.frac.sups').attr('style', '--sups-switch: off; --frac-switch: off;')
    $('.digits-module').find('.superscript').removeClass('sups').attr('style', '--sups-switch: off');
    $('#alt-features-digits').prop('checked', false);
  }
}
