import axios from 'axios';
import { validateEmail } from './validators';
import removeError from './form-helpers';

// Submitting authorization form (.login-form)
const authorize = async form => {
  const fieldset = form.querySelector('fieldset');
  const input = form.querySelector('input');
  const errorMessage = input.parentNode.parentNode.querySelector('[class*="error"]');

  const { kind } = form.dataset;

  form.addEventListener('submit', async event => {
    event.preventDefault();

    const emailValue = event.target.email.value;

    if (!validateEmail(emailValue)) {
      form.classList.add('error');
      setTimeout(() => form.classList.remove('error'), 700);
      return;
    }

    fieldset.disabled = true;

    try {
      await axios.post('/api/authorize', { email: emailValue, kind });
      form.classList.add('success');
    } catch (e) {
      fieldset.disabled = false;
      errorMessage.textContent = e.response.data.message;
      form.classList.add('error-state');
      console.log(e);
    }
  });

  input.addEventListener('keydown', () => removeError(form, 'error-state'));
  input.addEventListener('focusout', () => removeError(form, 'error-state'));
};

export default authorize;
