import * as glMatrix from 'gl-matrix';
import stickybits from 'stickybits'

const VS_SOURCE = `
  attribute vec4 aVertexPosition;
  attribute vec4 aVertexColor;

  uniform mat4 uModelViewMatrix;
  uniform mat4 uProjectionMatrix;

  varying lowp vec4 vColor;

  void main(void) {
    gl_Position = uProjectionMatrix * uModelViewMatrix * aVertexPosition;
    vColor = aVertexColor;
  }
`;

const FS_SOURCE = `
  varying lowp vec4 vColor;

  void main(void) {
    gl_FragColor = vColor;
  }
`;

const loadShader = (gl, type, source) => {
  const shader = gl.createShader(type);

  gl.shaderSource(shader, source);
  gl.compileShader(shader);

  return shader;
};

const initShaderProgram = (gl, vsSource, fsSource) => {
  const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vsSource);
  const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fsSource);
  const shaderProgram = gl.createProgram();

  gl.attachShader(shaderProgram, vertexShader);
  gl.attachShader(shaderProgram, fragmentShader);
  gl.linkProgram(shaderProgram);

  return shaderProgram;
};

const buildBuffer = (gl, type, data, size) => {
  const buffer = gl.createBuffer();
  const ArrayType = type === gl.ARRAY_BUFFER ? Float32Array : Uint32Array;

  gl.bindBuffer(type, buffer);
  gl.bufferData(type, new ArrayType(data), gl.STATIC_DRAW);

  buffer.itemSize = size;
  buffer.numItems = data.length / size;

  return buffer;
};

const initBuffers = (gl, data) => {
  const vlines = data.match(/^v\s+.*/gim);
  const flines = data.match(/^f\s+.*/gim);

  const vlength = vlines.length;
  const flength = flines.length;

  const positions = [];
  const colors = [];
  const indices = [];
  const center = { x: 0, y: 0, z: 0 };

  for (let vi = 0; vi < vlength; vi += 1) {
    const [x, y, z, r, g, b] = vlines[vi]
      .replace(/^v\s+/, '')
      .split(/\s+/)
      .map(str => +str);

    center.x += x;
    center.y += y;
    center.z += z;

    positions.push(x, y, z);
    colors.push(r, g, b);
  }

  for (let fi = 0; fi < flength; fi += 1) {
    const [n, m, k] = flines[fi]
      .replace(/^f\s+/, '')
      .split(/\s+/)
      .map(str => +str);

    indices.push(n - 1, m - 1, k - 1);
  }

  center.x /= positions.length / 3;
  center.y /= positions.length / 3;
  center.z /= positions.length / 3;

  const positionBuffer = buildBuffer(gl, gl.ARRAY_BUFFER, positions, 3);
  const colorBuffer = buildBuffer(gl, gl.ARRAY_BUFFER, colors, 3);
  const indexBuffer = buildBuffer(gl, gl.ELEMENT_ARRAY_BUFFER, indices, 1);

  return {
    position: positionBuffer,
    color: colorBuffer,
    indices: indexBuffer,
    count: indices.length,
    center,
  };
};

document.addEventListener('DOMContentLoaded', () => {
  const canvases = document.querySelectorAll('canvas.avatar-3d');

  canvases.forEach(canvas => {
    if (!canvas.dataset.file) return;

    const gl = canvas.getContext('webgl');

    gl.getExtension('OES_element_index_uint');

    const drawScene = ({
      program,
      buffers,
      vertexPositionLocation,
      vertexColorLocation,
      projectionMatrixLocation,
      modelViewMatrixLocation,
      angle,
    }) => {
      const { mat4, vec3 } = glMatrix;

      gl.clearColor(1.0, 1.0, 1.0, 1.0);
      gl.clearDepth(1.0);

      gl.enable(gl.DEPTH_TEST);
      gl.depthFunc(gl.LEQUAL);

      // eslint-disable-next-line
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

      const fieldOfView = (45 * Math.PI) / 180;
      const aspect = gl.canvas.clientWidth / gl.canvas.clientHeight;
      const projectionMatrix = mat4.create();

      mat4.perspective(projectionMatrix, fieldOfView, aspect, 0.1, 1000.0);

      const modelViewMatrix = mat4.create();

      const center = vec3.fromValues(
        buffers.center.x,
        buffers.center.y,
        buffers.center.z
      );

      // distance between center and eye
      const r = canvas.width * 0.75;

      const up = vec3.fromValues(0, -1, 0);
      const eye = vec3.fromValues(
        buffers.center.x + r * Math.cos(angle.x),
        buffers.center.y + r * Math.cos(angle.y),
        buffers.center.z + r * Math.sin(angle.x),
      );

      mat4.lookAt(modelViewMatrix, eye, center, up);

      gl.bindBuffer(gl.ARRAY_BUFFER, buffers.position);
      gl.vertexAttribPointer(vertexPositionLocation, 3, gl.FLOAT, true, 0, 0);
      gl.enableVertexAttribArray(vertexPositionLocation);

      gl.bindBuffer(gl.ARRAY_BUFFER, buffers.color);
      gl.vertexAttribPointer(vertexColorLocation, 3, gl.FLOAT, true, 0, 0);
      gl.enableVertexAttribArray(vertexColorLocation);

      gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffers.indices);

      gl.useProgram(program);

      gl.uniformMatrix4fv(projectionMatrixLocation, false, projectionMatrix);
      gl.uniformMatrix4fv(modelViewMatrixLocation, false, modelViewMatrix);

      gl.drawElements(gl.TRIANGLES, buffers.count, gl.UNSIGNED_INT, 0);
    };

    const mainRender = data => {
      const program = initShaderProgram(gl, VS_SOURCE, FS_SOURCE);
      const buffers = initBuffers(gl, data);

      const vertexPositionLocation = gl.getAttribLocation(
        program,
        'aVertexPosition'
      );

      const vertexColorLocation = gl.getAttribLocation(program, 'aVertexColor');

      const projectionMatrixLocation = gl.getUniformLocation(
        program,
        'uProjectionMatrix'
      );

      const modelViewMatrixLocation = gl.getUniformLocation(
        program,
        'uModelViewMatrix'
      );

      const angle = { x: 0.5 * Math.PI, y: 0.5 * Math.PI };

      drawScene({
        program,
        buffers,
        vertexPositionLocation,
        vertexColorLocation,
        projectionMatrixLocation,
        modelViewMatrixLocation,
        angle
      });

      const onMoveAvatar = event => {
        const canvasRect = canvas.getBoundingClientRect();
        const canvasX = canvasRect.left + canvasRect.width * 0.5;
        const canvasY = canvasRect.top + canvasRect.height * 0.5;

        const clientX = event.clientX ||
          (event.touches && event.touches.length ? event.touches[0].clientX : canvasX);
        const clientY = event.clientY ||
          (event.touches && event.touches.length ? event.touches[0].clientY : canvasY);

        // угол по оси х - [0, 1], по оси y - [0.25, 0.75]

        if (clientX > canvasX) {
          angle.x = (0.5 - (clientX - canvasX) / (window.innerWidth - canvasX) * 0.5) * Math.PI;
        } else {
          angle.x = (1 - clientX / canvasX * 0.5) * Math.PI;
        }

        if (clientY > canvasY) {
          angle.y = (0.5 + (clientY - canvasY) / (window.innerHeight - canvasY) * 0.25) * Math.PI;
        } else {
          angle.y = (0.25 + clientY / canvasY * 0.25) * Math.PI;
        }

        drawScene({
          program,
          buffers,
          vertexPositionLocation,
          vertexColorLocation,
          projectionMatrixLocation,
          modelViewMatrixLocation,
          angle
        });
      };

      document.addEventListener('mousemove', onMoveAvatar);
      document.addEventListener('touchmove', onMoveAvatar);
    };

    fetch(canvas.dataset.file)
      .then(response => response.text())
      .then(data => {
        mainRender(data);
      });
  });

  stickybits('.avatar-wrapper', { useStickyClasses: true });
});
