import $ from 'jquery';

const isObserverAvailable = typeof IntersectionObserver !== 'undefined';

const preloadImages = element => {
  $(element)
    .find('img')
    .each((__, image) => {
      if (!$(image).attr('src') || $(image).attr('src').length < 1) {
        $(image)
          .attr('src', $(image).attr('data-src'))
          .addClass('loading')
          .one('load', () => {
            $(image).removeClass('loading');
            if (
              $(image)
                .parents('article')
                .find('img.loading').length < 1
            )
              $(image)
                .parents('article')
                .removeClass('loading')
                .addClass('loaded');
          });
      }
    });
};

const intersectionObserver =
  isObserverAvailable &&
  new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          preloadImages(entry.target);
          intersectionObserver.unobserve(entry.target);
        }
      });
    },
    { rootMargin: '0px 0px 20% 0px' }
  );

const makeHoverWidget = () => {
  $('.hover ').each((_, widget) => {
    const $imgs = $(widget).find('img');

    if ($imgs.length === 1 && $imgs.attr('data-count') * 1 > 1) {
      const url = $imgs.attr('data-src');
      // eslint-disable-next-line no-plusplus
      for (let i = $imgs.attr('data-count'); i > 1; i--) {
        const nurl = url.replace(/([A-Za-z0-9_\-%]+)\d\.(\w)/gi, `$1${i}.$2`);
        $imgs.after(
          `<img data-src="${nurl}" height="${$imgs.attr('height')}"/>`
        );
      }
    }

    if (isObserverAvailable) {
      intersectionObserver.observe(widget);
    } else {
      preloadImages(widget);
    }
  });

  $('.grid')
    .on('mousemove touchmove', '.hover .image-wrapper', e => {
      const element = e.currentTarget;
      const pageX = e.originalEvent.touches
        ? e.originalEvent.touches[0].pageX
        : e.pageX;

      const imgcnt = $(element).find('img').length;
      const mywidth = $(element).width();
      const $slider = $(element).find('.image-slider');
      const imgwidth = $(element)
        .find('img')
        .width();
      const imgind = Math.ceil(-$slider.css('left') / imgwidth);
      let newind = Math.floor(
        (pageX -
          $(element)
            .parents('.hover')
            .offset().left) /
          (mywidth / imgcnt)
      );
      if (imgcnt - 1 < newind) {
        newind = imgcnt - 1;
      } else if (newind <= 0) newind = 0;

      if (imgind !== newind && $slider.queue('fx').length === 0) {
        $slider.css('left', -(newind * imgwidth));

        $(element)
          .siblings('.image-indicator')
          .width(mywidth)
          .find('.image-indicator-active')
          .width(mywidth / imgcnt)
          .css('left', (newind * mywidth) / imgcnt);
      }
    })

    .on('mouseenter touchstart', '.hover .image-wrapper', e => {
      $(e.currentTarget)
        .siblings('.image-indicator')
        .fadeIn(10);
    })
    .on('mouseleave touchend', '.hover .image-wrapper', e => {
      $(e.currentTarget)
        .siblings('.image-indicator')
        .fadeOut('fast');
    });

  $('.hover').each((_, widget) => {
    if ($(widget).find('.promo-text a').length > 0) {
      $(widget)
        .find('.image-wrapper')
        .attr(
          'href',
          $(widget)
            .find('.promo-text a')
            .first()
            .attr('href')
        );
      const link = $(widget)
        .find('.promo-text a')
        .first();

      link.on('mouseenter touchstart', e => {
        $(e.currentTarget)
          .parents('article')
          .addClass('hovered');
      });

      link.on('mouseleave touchend', e => {
        $(e.currentTarget)
          .parents('article')
          .removeClass('hovered');
      });
    }
  });
};

$(makeHoverWidget);
