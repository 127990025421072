import $ from 'jquery';

document.addEventListener('DOMContentLoaded', () => {
  $.each($('.footnote-link'), (index, elem) => {
    elem.setAttribute('data-after', index + 1);
    $('.footnote p:first-of-type')[index].setAttribute(
      'data-before',
      index + 1
    );
    $('.footnote')[index].setAttribute('data-after', index + 1);
  });

  $('body').on('click', e => {
    const isActive = $(e.target).hasClass('active');

    const hideFootnote = () => {
      $('.footnote').hide();
      $('.footnote-link').removeClass('active');
      $('.footnote').removeClass('scrollable');
      $('.footnote').find('.close-footnote').remove();
    };

    if ($(e.target).hasClass('footnote-link')) {
      if (isActive) {
        hideFootnote();
      } else {
        hideFootnote();
        $(e.target)
          .closest('.footnote-paragraph')
          .find(`.footnote[data-after=${$(e.target).data('after')}]`)
          .show();
          
        $(e.target).addClass('active');
        
        if (window.matchMedia("(max-width: 768px)").matches) {
          const footnoteBody = $(e.target).parents('.footnote-paragraph').find('.footnote');
          
          if (footnoteBody.innerHeight() >= window.innerHeight) {
            footnoteBody.addClass('scrollable');
            footnoteBody.append(`
              <button class="close close-footnote">
                <svg viewBox="0 0 20 20">
                  <g>
                    <line x1="0" y1="20" x2="20" y2="0"/>
                    <line x1="0" y1="0" x2="20" y2="20"/>
                  </g>
                </svg>
                Закрыть
              </button>
            `)
            
            $('body').css('overflow', 'hidden');
          }
        }
      }
    } else if (!$(e.target).parents('.footnote').length || $(e.target).hasClass('close-footnote')) {
      hideFootnote();
      $('body').css('overflow', 'auto');
    } 
  });
});
