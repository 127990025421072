// -------------
// файл с классом, создающий карусель
// файл стилей для карусели
// папка ассетов для карусели
// -------------

function Timer(callback, delay) {
  let timerId;
  let start;
  let remaining = delay;
  let paused = false;

  this.paused = () => paused;

  this.pause = () => {
    paused = true;
    clearTimeout(timerId);
    remaining -= Date.now() - start;
  };

  this.resume = () => {
    paused = false;
    start = Date.now();
    clearTimeout(timerId);
    timerId = setTimeout(callback, remaining);
  };

  this.stop = () => {
    clearTimeout(timerId);
  };

  this.resume();
}

const createElement = (tag, { classes = '', style = {}, ...options } = {}) => {
  const element = document.createElement(tag);

  element.className = classes;
  Object.assign(element.style, style);

  Object.entries(options || {}).forEach(([key, value]) =>
    element.setAttribute(key, value)
  );

  return element;
};

class StoriesCarousel {
  constructor(selector, duration) {
    this.items = document.getElementsByClassName(selector);
    this.defaultDuration = duration;
    this.slideDuration = duration;

    this.body = document.querySelector('body');

    this.timer = null;
    this.opened = false;
    this.currentImg = 0;
    this.touchMoveStart = 0;
    this.touchTimeStart = 0;

    this.init();
  }

  init() {
    // create carousel container (popup)
    this.popup = createElement('div', { classes: 'carousel-popup' });
    this.body.appendChild(this.popup);

    // add images or videos to popup (src from data-src attribute)
    this.images = [...this.items].map(item => {
      const src = item.getAttribute('data-src');
      const isVideo = item.getAttribute('data-type') === 'video';
      const subtitlesSrc = item.getAttribute('data-subtitles');
      const tag = isVideo ? 'video' : 'img';
      const props = isVideo ? { autoplay: '', playsinline: '' } : { src };

      const img = createElement(tag, {
        classes: 'carousel-popup-image',
        draggable: false,
        ...props,
      });

      if (isVideo) {
        img.controls = false;
        img.currentTime = 0;
        img.addEventListener('loadstart', () => {
          img.pause();
        });

        const source = createElement('source', {
          src,
          type: 'video/mp4',
        });
        img.appendChild(source);
        if (subtitlesSrc) {
          const track = createElement('track', {
            kind: 'subtitles',
            src: subtitlesSrc,
            label: 'Russian',
            srclang: 'ru',
            default: '',
          });
          img.appendChild(track);
        }
      }
      img.addEventListener('mousedown', () => this.pauseStory());
      img.addEventListener('touchstart', event => {
        this.touchMoveStart = event.changedTouches[0].clientX;
        this.touchTimeStart = new Date().getTime();
        this.pauseStory();
      });

      this.popup.appendChild(img);
      return img;
    });

    this.hasActionsStory = !!this.items[this.items.length - 1].getAttribute(
      'data-link'
    );

    // add buttons to close stories visible on last story
    if (this.hasActionsStory) {
      this.buttons = createElement('div', {
        classes: 'carousel-popup-actions',
      });

      const text = createElement('div', { classes: 'carousel-text' });
      text.innerHTML =
        'Ищем<br>пиццамейкеров,<br>кассиров,<br>курьеров и&nbsp;менеджеров<br>смены!';

      const linkButton = createElement('button', { classes: 'carousel-form' });
      linkButton.innerText = 'Заполнить анкету';
      linkButton.addEventListener('click', () => {
        window.location.href = this.items[this.items.length - 1].getAttribute(
          'data-link'
        );
        this.onClosePopup();
      });

      const closeButton = createElement('button', {
        classes: 'carousel-close',
      });
      closeButton.innerText = 'Закрыть и читать дальше';
      closeButton.addEventListener('click', () => this.onClosePopup());

      this.buttons.appendChild(text);
      this.buttons.appendChild(linkButton);
      this.buttons.appendChild(closeButton);
      this.popup.appendChild(this.buttons);
    }

    // add lines to indicate stories
    this.linesContainer = createElement('div', {
      classes: 'carousel-popup-lines',
    });
    this.lines = [...this.items].map(() => {
      const line = createElement('div', { classes: 'carousel-popup-line' });

      line.addEventListener('transitionend webkitTransitionEnd', () => {
        line.style.transition = 'none';
      });

      this.linesContainer.appendChild(line);
      return line;
    });

    // add question
    this.question = createElement('div', {
      classes: 'carousel-popup-question',
    });
    this.linesContainer.appendChild(this.question);
    this.popup.appendChild(this.linesContainer);

    // add avatars if specified in data attributes
    this.avatars = [...this.items].map(item => {
      const avatar = item.getAttribute('data-avatar');
      const name = item.getAttribute('data-name');

      const avatarContainer = createElement('div', {
        classes: 'carousel-popup-avatars',
      });

      if (avatar) {
        const img = createElement('img', {
          draggable: false,
          src: avatar,
        });
        avatarContainer.appendChild(img);
      }
      if (name) {
        const nameContainer = createElement('div');
        nameContainer.innerText = name;
        avatarContainer.appendChild(nameContainer);
      }

      this.popup.appendChild(avatarContainer);
      return avatarContainer;
    });

    // add close button
    this.cross = createElement('div', {
      classes: 'carousel-popup-button close',
    });
    this.cross.addEventListener('click', () => this.onClosePopup());
    this.cross.addEventListener('touchend', event => event.stopPropagation());
    this.cross.appendChild(
      createElement('img', { src: 'images/carousel/cross.svg' })
    );
    this.popup.appendChild(this.cross);

    // add prev and next areas for handlers
    const nextArea = createElement('div', {
      classes: 'carousel-popup-area next',
    });
    const prevArea = createElement('div', {
      classes: 'carousel-popup-area prev',
    });

    nextArea.addEventListener('click', event => this.onChangeImg(event, 1));
    nextArea.addEventListener('touchend', event => event.stopPropagation());

    prevArea.addEventListener('click', event => this.onChangeImg(event, -1));
    prevArea.addEventListener('touchend', event => event.stopPropagation());

    this.popup.appendChild(nextArea);
    this.popup.appendChild(prevArea);

    // add prev and next buttons
    this.next = createElement('img', {
      classes: 'carousel-popup-button next',
      src: 'images/carousel/next.svg',
    });
    this.prev = createElement('img', {
      classes: 'carousel-popup-button prev',
      src: 'images/carousel/next.svg',
    });

    nextArea.appendChild(this.next);
    prevArea.appendChild(this.prev);

    // handle popup events
    this.popup.addEventListener('touchmove', event => {
      event.preventDefault();
      event.stopPropagation();
    });
    this.popup.addEventListener('mouseup', () => this.resumeStory());
    this.popup.addEventListener('touchend', event => {
      const touchMoveEnd = event.changedTouches[0].clientX;
      const touchMoveDelta = touchMoveEnd - this.touchMoveStart;
      const minTouchMove = 10;

      this.resumeStory();

      if (touchMoveDelta > minTouchMove) {
        // swipe right
        this.onChangeImg(event, -1);
      } else if (touchMoveDelta < -minTouchMove) {
        // swipe left
        this.onChangeImg(event, 1);
      } else {
        const touchTimeEnd = new Date().getTime();
        const touchTimeDelta = touchTimeEnd - this.touchTimeStart;
        const minTouchTime = 300;

        if (touchTimeDelta < minTouchTime) {
          const { width, left, right } = this.images[
            this.currentImg
          ].getBoundingClientRect();

          if (this.touchMoveStart < left + width * 0.33) {
            // tap on left part -> move to prev
            this.onChangeImg(event, -1);
          } else if (this.touchMoveStart > right - width * 0.66) {
            // tap on right part -> move to next
            this.onChangeImg(event, 1);
          }
        }
      }
    });

    // handle keyboard events to close and change img
    this.body.addEventListener('keydown', event => {
      if (!this.opened) return;

      if (event.code === 'Escape') this.onClosePopup();
      else if (event.code === 'ArrowRight') this.onChangeImg(event, 1);
      else if (event.code === 'ArrowLeft') this.onChangeImg(event, -1);
    });

    // handle window resize event
    window.addEventListener('resize', () => this.positionElements(), true);

    // handle items clicks to open popup
    [...this.items].forEach((item, index) => {
      item.addEventListener('click', event => this.startStories(event, index));
    });

    // handle click on intro text
    const introText = document.querySelector('.stories-caption');
    if (introText) {
      introText.addEventListener('click', event => this.startStories(event, 0));
    }
  }

  startStories(event, index) {
    event.stopPropagation();

    this.currentImg = index;
    [...Array(this.items.length).keys()].forEach(itemIndex => {
      const isPrev = itemIndex < this.currentImg;
      const isCurrent = itemIndex === this.currentImg;

      this.images[itemIndex].classList.toggle('visible', isCurrent);
      this.avatars[itemIndex].classList.toggle('visible', isCurrent);
      this.lines[itemIndex].classList.toggle('active', isPrev);
    });

    this.setDuration();
    this.positionElements();
    this.onOpenPopup();
    this.startVideo();
    this.startLineAnimation(this.currentImg);
    this.setActionButtons();
    this.setQuestion();

    // start story playing
    this.timer = new Timer(() => this.onChangeImg(null, 1), this.slideDuration);
  }

  setDuration() {
    this.slideDuration = this.isVideo()
      ? this.images[this.currentImg].duration * 1000
      : this.defaultDuration;
  }

  isVideo() {
    return this.images[this.currentImg].tagName === 'VIDEO';
  }

  setActionButtons() {
    if (this.hasActionsStory) {
      this.buttons.classList.toggle(
        'visible',
        this.currentImg === this.items.length - 1
      );
    }
  }

  setQuestion() {
    const text =
      this.items[this.currentImg].getAttribute('data-question') || '';

    this.question.innerHTML = '';
    this.question.className = `carousel-popup-question carousel-popup-question-${
      this.currentImg
    }`;
    if (text) {
      text.split('\n').forEach(str => {
        const span = createElement('span');
        span.innerText = str;
        this.question.appendChild(span);
      });
    }
  }

  stopVideo(reload) {
    if (this.isVideo()) {
      const video = this.images[this.currentImg];
      if (video.readyState !== 0) {
        video.pause();
        if (reload) video.currentTime = 0;
      }
    }
  }

  startVideo() {
    if (this.isVideo()) {
      const video = this.images[this.currentImg];
      if (video.readyState !== 0) {
        video.play();
      }
    }
  }

  pauseStory() {
    const { background, backgroundPositionX } = getComputedStyle(
      this.lines[this.currentImg]
    );

    this.lines[this.currentImg].classList.remove('active');
    this.lines[this.currentImg].style.background = background;
    this.lines[this.currentImg].style.backgroundPositionX = backgroundPositionX;
    this.stopVideo();
    this.timer.pause();
  }

  resumeStory() {
    if (!this.timer.paused) return;

    this.lines[this.currentImg].style.removeProperty('background');
    this.lines[this.currentImg].style.removeProperty('background-position-x');
    this.lines[this.currentImg].classList.add('active');
    this.startVideo();
    this.timer.resume();
  }

  startLineAnimation(index) {
    this.lines[index].style.transition = `background-position-x ${
      this.slideDuration
    }ms linear`;
    this.lines[index].classList.add('active');
  }

  removeLineAnimation(index) {
    this.lines[index].classList.remove('active');
    this.lines[index].style.transition = 'none';
  }

  onOpenPopup() {
    this.opened = true;
    this.popup.classList.toggle('opened', this.opened);
    this.body.style.overflow = 'hidden';
  }

  onClosePopup() {
    this.timer.stop();
    this.stopVideo(true);
    this.opened = false;
    this.popup.classList.toggle('opened', this.opened);
    this.body.style.overflow = 'auto';
    this.lines.forEach((_, index) => this.removeLineAnimation(index));
  }

  onChangeImg(event, shift) {
    if (event) event.stopPropagation();

    if (
      (this.currentImg === this.images.length - 1 && shift === 1) ||
      (this.currentImg === 0 && shift === -1)
    ) {
      return;
    }

    this.timer.stop();
    this.stopVideo(true);

    this.currentImg += shift;

    this.setDuration();
    this.positionElements();
    this.startVideo();
    this.setActionButtons();
    this.setQuestion();

    [...Array(this.items.length).keys()].forEach(index => {
      const isCurrent = index === this.currentImg;

      this.images[index].classList.toggle('visible', isCurrent);
      this.avatars[index].classList.toggle('visible', isCurrent);

      if (isCurrent) {
        this.lines[index].classList.remove('active');
        this.lines[index].offsetHeight; // force reflow to reset animation
        this.startLineAnimation(this.currentImg);
      } else {
        this.lines[index].style.transition = 'none';
        this.lines[index].classList.toggle('active', this.currentImg > index);
      }
    });

    // start next story
    if (this.currentImg !== this.images.length - 1) {
      this.timer = new Timer(() => {
        this.onChangeImg(null, 1);
      }, this.slideDuration);
    }
  }

  positionElements() {
    const { width, height, top, bottom, left, right } = this.images[
      this.currentImg
    ].getBoundingClientRect();

    const linesPadding = parseInt(
      getComputedStyle(this.linesContainer).paddingLeft,
      10
    );
    const { length } = this.images;
    const linesWidth = width - linesPadding * 2;
    const lineMargin = parseInt(
      getComputedStyle(this.lines[this.currentImg]).marginLeft,
      10
    );
    const lineWidth = Math.floor(linesWidth / length - lineMargin * 2);

    this.linesContainer.style.top = `${top}px`;
    this.linesContainer.style.width = `${width}px`;
    this.lines.forEach(line => {
      line.style.width = `${lineWidth}px`;
    });

    if (this.currentImg === 0) {
      this.prev.classList.add('hidden');
    } else {
      this.prev.classList.remove('hidden');
      this.prev.style.left = `${left - this.prev.offsetWidth}px`;
    }

    if (this.currentImg === this.images.length - 1) {
      this.next.classList.add('hidden');
    } else {
      this.next.classList.remove('hidden');
      this.next.style.left = `${right}px`;
    }

    this.avatars[this.currentImg].style.top = `${bottom -
      this.avatars[this.currentImg].offsetHeight}px`;

    if (this.hasActionsStory && this.currentImg === length - 1) {
      this.buttons.style.width = `${width}px`;
      this.buttons.style.height = `${height}px`;
      this.buttons.style.top = `${top}px`;
    }
  }
}

document.addEventListener('DOMContentLoaded', event => {
  if (event.target.URL.includes('dodo-jobs')) {
    new StoriesCarousel('stories-item', 5000);
  }
});
