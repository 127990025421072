import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV === 'production') {
  // TODO: переместить DSN в .env файл на сервере, засетапить в вебпаке инжект этой переменной
  Sentry.init({
    dsn:
      'https://e508d8be5c6640de99e30935154507a9@o572482.ingest.sentry.io/4504632493932544',
    integrations: [],
    tracesSampleRate: 0.25,
  });
}
